import React, { Component } from 'react';
import Digipointservice from '../service/Digipointservice';
import { Panel } from 'primereact/panel';
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Divider } from 'primereact/divider';

import { Dropdown } from 'primereact/dropdown';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';

import { RadialGauge } from 'react-canvas-gauges';



export class Dashboard extends Component {

    constructor() {
        super();
        this.state = {
            liste_retard: [],
            liste_st_retard_suplem: [],
            liste_suplem: [],
            liste_abs: [],
            liste_pointage: [],
            liste_dept: [],
            nb_presence: 0,
            nb_absence: 0,
            nbh_retard: 0,
            nbh_suplem: 0,
            nbh_travail: 0,
            nbh_travail_prev: 0,
            taux_travail: 0,
            dept: null,
            rid_dept: '',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            // fic_url: 'http://10.173.36.47:8888/fic_digipoint',
            fic_url: 'http://localhost:8888/fic_digipoint',
            showindicator: false
        };
        this.myservice = new Digipointservice();
        this.itemTemplate_suplem = this.itemTemplate_suplem.bind(this);
        this.itemTemplate_retard = this.itemTemplate_retard.bind(this);
        this.itemTemplate_abs = this.itemTemplate_abs.bind(this);

    }




    charger_dashboard(id_dept) {
        this.setState({ showindicator: true });
        this.myservice.get_dashboard(new Intl.DateTimeFormat('en-GB').format(new Date()), id_dept).then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_abs: data.liste_abs });
                this.setState({ liste_pointage: data.liste_pointage });
                this.setState({ liste_retard: data.liste_retard });
                this.setState({ liste_suplem: data.liste_suplem });
                this.setState({ liste_st_retard_suplem: data.liste_st_retard_suplem });
                this.setState({ nb_presence: data.nb_presence });
                this.setState({ nb_absence: data.nb_absence });
                this.setState({ nbh_retard: data.nbh_retard });
                this.setState({ nbh_suplem: data.nbh_suplem });
                this.setState({ taux_travail: data.taux_travail });

            }
            this.setState({ showindicator: false });
        }
        );
    }

    componentDidMount() {
        this.myservice.getListe_dept().then(data => {
            this.setState({ liste_dept: data }, () => {
                if (this.state.userconnected != null) {
                    this.setState({ dept: { value: this.state.userconnected.idSrv.idDiv.idDept.idDept, label: this.state.userconnected.idSrv.idDiv.idDept.nomDept } });
                    if (this.state.userconnected.idGrp.nomGrp == 'ADMINISTRATEUR') {
                        this.charger_dashboard('-1');
                    } else {
                        this.charger_dashboard(this.state.userconnected.idSrv.idDiv.idDept.idDept + '');
                    }
                }
            });
        }
        );
    }

    renderGridItem(item) {
        var src = this.myservice.download_file(item.photo_user, 'photos');
        return (
            <div style={{ padding: '.5em' }} className="p-col-12 p-md-3">
                <Panel style={{ textAlign: 'center' }}>
                    <img src={src} alt={item.nom_user} />
                    <div className="car-detail">{item.code_srv}</div>
                </Panel>
            </div>
        );
    }
    renderListItem_retard(item) {
        var src = this.myservice.download_file(item.photo_user, 'photos');
        return (
            <div className="p-col-12" >
                <div className="p-grid" style={{ height: 75, padding: 15 }}>
                    <div className="p-col-3">
                        <img src={src} alt={item.nom_user} style={{ width: '60px', height: '60px', borderRadius: '50%' }} />
                    </div>
                    <div className="p-col-7" style={{ marginLeft: -45 }}>
                        <span className="name" style={{ fontSize: '18px', fontWeight: 'bold' }}>{item.nom_user}</span>
                        <br />
                        <span className="location">{item.nom_srv}</span>
                        <br />
                        <span style={{ color: '#272f3a' }}>{item.matr_user}</span>
                    </div>
                    <div className="p-col-2">
                        <span className="name" style={{ fontSize: '18px', fontWeight: 'bold', color: '#959392' }}>{item.nbh_retard} H</span>
                    </div>
                </div>

            </div>
        );
    }

    renderListItem_suplem(item) {
        var src = this.myservice.download_file(item.photo_user, 'photos');
        return (
            <div className="p-col-12"  >
                <div className="p-grid" style={{ height: 75, padding: 15 }}>
                    <div className="p-col-3">
                        <img src={src} alt={item.nom_user} style={{ width: '60px', height: '60px', borderRadius: '50%' }} />
                    </div>
                    <div className="p-col-6" style={{ marginLeft: -45 }}>
                        <span className="name" style={{ fontSize: '18px', fontWeight: 'bold' }}>{item.nom_user}</span>
                        <br />
                        <span className="location">{item.nom_srv}</span>
                        <br />
                        <span style={{ color: '#272f3a' }}>{item.matr_user}</span>
                    </div>
                    <div className="p-col-3">
                        <span className="name" style={{ fontSize: '18px', fontWeight: 'bold', color: '#959392' }}>{item.nbh_suplem} H</span>
                    </div>
                </div>

            </div>


        );
    }

    renderListItem_abs(item) {
        var src = this.myservice.download_file(item.photo_user, 'photos');
        return (
            <div className="p-col-12" >
                <div className="p-grid" style={{ height: 75, padding: 15 }}>
                    <div className="p-col-3">
                        <img src={src} alt={item.nom_user} style={{ width: '60px', height: '60px', borderRadius: '50%' }} />
                    </div>
                    <div className="p-col-7" style={{ marginLeft: -45 }}>
                        <span className="name" style={{ fontSize: '18px', fontWeight: 'bold' }}>{item.nom_user}</span>
                        <br />
                        <span className="location">{item.nom_srv}</span>
                        <br />
                        <span style={{ color: '#272f3a' }}>{item.matr_user}</span>
                    </div>
                    <div className="p-col-2">
                        <span className="name" style={{ fontSize: '18px', fontWeight: 'bold', color: '#959392' }}>{item.nb_abs} H</span>
                    </div>
                </div>

            </div>

        );
    }

    itemTemplate_suplem(item) {
        if (!item) {
            return;
        }
        return this.renderListItem_suplem(item);
    }

    itemTemplate_abs(item) {
        if (!item) {
            return;
        }
        return this.renderListItem_abs(item);
    }

    itemTemplate_retard(item) {
        if (!item) {
            return;
        }
        return this.renderListItem_retard(item);
    }

    render() {

        let ldepts = null;
        if (this.state.liste_dept != null && this.state.liste_dept != undefined && this.state.liste_dept.length > 0) {
            let l1 = this.state.liste_dept.map(x => {
                return { value: x.idDept, label: x.nomDept }
            });
            let l2 = [{ value: '-1', label: 'Départements' }];
            ldepts = l2.concat(l1);
        }

        let disable_dept = true;
        if (this.state.userconnected != null && this.state.userconnected != undefined) {
            if (this.state.userconnected.idGrp.nomGrp == 'ADMINISTRATEUR') {
                disable_dept = false;
            }
        }

        let liste_label = null;
        let liste_ds_retard = null;
        let liste_ds_suplem = null;
        if (this.state.liste_st_retard_suplem != null && this.state.liste_st_retard_suplem != undefined && this.state.liste_st_retard_suplem.length > 0) {
            liste_label = this.state.liste_st_retard_suplem.map(x => {
                return x.mois
            });

            liste_ds_retard = this.state.liste_st_retard_suplem.map(x => {
                return x.nbh_retard
            });

            liste_ds_suplem = this.state.liste_st_retard_suplem.map(x => {
                return x.nbh_suplem
            });
        }

        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    label: 'Heures suplémentaires',
                    backgroundColor: '#669966',
                    borderColor: '#669966',
                    fill: false,
                    data: liste_ds_suplem
                },
                {
                    label: 'Heures retards',
                    backgroundColor: '#FC5146',
                    borderColor: '#FC5146',
                    fill: false,
                    data: liste_ds_retard
                }
            ]
        };



        return <div className="p-grid dashboard" >
            <div className="p-col-12" >
                <div className="p-grid" style={{ float: "right", width: 400 }}>
                    <div className="p-col">
                        <Dropdown disabled={disable_dept} style={{ width: 300, marginBottom: -8 }} value={this.state.dept} options={ldepts} onChange={(e) => {
                            this.setState({ dept: e.value, rid_dept: e.value.value });
                            this.charger_dashboard(e.value.value);
                        }
                        } filter={true} filterBy='label' optionLabel="label" placeholder="Département" />
                    </div>
                    <div className="p-col">
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </div>
                </div>
            </div>


            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-1" style={{ height: 70 }} ><h1>TOTAL PRESENTS</h1>
                    <div className="overview-value">{this.state.nb_presence}</div>
                    <img src="assets/layout/images/dashboard/graph-blue.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-6 p-sm-6 p-md-3" >
                <div className="overview-box overview-box-2" style={{ height: 70 }}>
                    <h1>TOTAL ABSENTS</h1>
                    <div className="overview-value">{this.state.nb_absence}</div>
                    <img src="assets/layout/images/dashboard/graph-green.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-3" style={{ height: 70 }}>
                    <h1>RETARDS</h1>
                    <div className="overview-value">{this.state.nbh_retard} H</div>
                    <img src="assets/layout/images/dashboard/graph-yellow.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-6 p-sm-6 p-md-3">
                <div className="overview-box overview-box-4" style={{ height: 70 }}>
                    <h1>HEURES SUPPLEMENTAIRES</h1>
                    <div className="overview-value">{this.state.nbh_suplem} H</div>
                    <img src="assets/layout/images/dashboard/graph-red.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-12" >
                <div className="p-grid">
                    <div className="p-col-12 p-lg-4 p-md-6" style={{minHeight: 200,backgroundColor: '#fff'}}>
                        <div className="status-title" style={{ color: '#6ebc3b' }}>TOP 5 DES HEURES SUPPLEMENTAIRES</div>
                        <ScrollPanel style={{ width: '100%', height: '200px' }}>
                            <DataView value={this.state.liste_suplem} layout='list'
                                itemTemplate={this.itemTemplate_suplem} 
                            />
                        </ScrollPanel>
                    </div>

                    <div className="p-col-12 p-lg-4 p-md-6" style={{minHeight: 200,backgroundColor: '#F7F7F7'}}>
                        <div className="status-title" style={{ color: '#f6a821' }}>TOP 5 DES RETARDATAIRES</div>
                        <ScrollPanel style={{ width: '100%', height: '200px' }}>
                            <DataView value={this.state.liste_retard} layout='list'
                                itemTemplate={this.itemTemplate_retard}
                            />
                        </ScrollPanel>
                    </div>

                    <div className="p-col-12 p-lg-4 p-md-6" style={{minHeight: 200,backgroundColor: '#fff'}}>
                        <div className="status-title" style={{ color: '#FC5146' }}>TOP 5 DES ABSENTS</div>
                        <ScrollPanel style={{ width: '100%', height: '200px' }}>
                            <DataView value={this.state.liste_abs} layout='list'
                                itemTemplate={this.itemTemplate_abs} 
                            />
                        </ScrollPanel>
                    </div>

                </div>
            </div>


            <div className="p-col-12 p-md-6 p-lg-3">
                <Panel style={{ minHeight: '400px' }}>
                    <i className="pi pi-chart-line"></i> Taux de travail du jour
                    <hr />

                    <RadialGauge
                        units='%'
                        title='Taux de travail'
                        value={this.state.taux_travail}
                        minValue={0}
                        maxValue={100}
                        colorValueBoxShadow={false}
                        highlights={[
                            { from: 0, to: 60, color: 'rgba(255,0,0,.60)' },
                            { from: 60, to: 85, color: 'rgba(255,255,0,.60)' },
                            { from: 85, to: 100, color: 'rgba(0,128,0,.60)' }
                        ]}
                        majorTicks={['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100']}
                        minorTicks={2}
                    />

                </Panel>
            </div>

            <div className="p-col-12 p-md-6 p-lg-9 p-fluid">
                <Panel style={{ minHeight: '400px' }}>
                    <i className="pi pi-chart-line"></i> Situation du jour par service
                    <hr />
                    <DataTable value={this.state.liste_pointage} scrollable={true} scrollHeight="225px"
                        responsive={true} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                        <Column field="nom_srv" header="Service" sortable={true} filter={true} filterMatchMode='contains' style={{ width: '32%' }} />
                        <Column field="nom_div" header="Division" sortable={true} filter={true} filterMatchMode='contains' style={{ width: '25%' }} />
                        <Column field="code_dept" header="Département" sortable={true} filter={true} filterMatchMode='contains' style={{ width: '15%' }} />
                        <Column field="nb_abs" header="Nb absents" sortable={true} filter={true} filterMatchMode='contains' style={{ width: '12%' }} />
                        <Column field="nb_present" header="Nb présents" sortable={true} filter={true} filterMatchMode='contains' style={{ width: '14%' }} />
                    </DataTable>
                </Panel>
            </div>

            <div className="p-col-12">
                <Panel className="no-pad">
                    <i className="pi pi-chart-line"></i> Statistiques des retards (année en cours)
                    <hr />
                    <div className="content-section implementation">
                        <Chart type="line" data={chart_data} height="100w" />
                    </div>
                </Panel>
            </div>

        </div>
    }
}