import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chart } from 'primereact/chart';


export class Form_situation_pointage_div extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rid_user: '',
            liste_loc: [],
            liste_point: [],
            liste_user: [],
            site: null,
            user: null,
            showindicator: false
        };
        this.export = this.export.bind(this);
        this.myservice = new Digipointservice();
        this.state.pd1.setHours(0, 0, 0);
    }

    componentDidMount() {
        this.get_liste_point();
    }

    get_liste_point() {
        this.setState({ showindicator: true });
        var spd1 = moment(this.state.pd1).format('DD/MM/YYYY H:mm:ss');
        var spd2 = moment(this.state.pd2).format('DD/MM/YYYY H:mm:ss');
        this.myservice.situtation_pointage_div(spd1, spd2).then(data => this.setState({ liste_point: data, showindicator: false }));
    }

    export() {
        this.dt.exportCSV();
    }

    render() {

        var header = <div style={{ textAlign: 'left', width: 120 }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="Exporter" onClick={this.export}></Button></div>;

        let liste_label = null;
        let liste_ds_retard = null;
        let liste_ds_travail = null;
        if (this.state.liste_point != null && this.state.liste_point != undefined && this.state.liste_point.length > 0) {
            liste_label = this.state.liste_point.map(x => {
                return x.code_div
            });

            liste_ds_retard = this.state.liste_point.map(x => {
                return x.nbh_retard
            });

            liste_ds_travail = this.state.liste_point.map(x => {
                return x.nbh_trav
            });
        }

        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    label: 'Heures travail',
                    backgroundColor: '#669966',
                    data: liste_ds_travail
                },
                {
                    label: 'Heures retards',
                    backgroundColor: '#FC5146',
                    data: liste_ds_retard
                }
            ]
        };

        return (
            <div className="p-fluid">
                <div className="p-grid">

                    <div className="p-col-12 p-lg-6">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 700 }}>
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-3">
                                                <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} showTime={true} showSeconds={true} />
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} showTime={true} showSeconds={true} />
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_point()} />
                                            </div>
                                            <div className="p-col-12 p-md-3">
                                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                            </div>
                                        </div>
                                    </div>

                                    <DataTable value={this.state.liste_point} scrollable={true} scrollHeight="320px"
                                        responsive={true} header={header} ref={(el) => { this.dt = el; }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                        <Column field="ordre" header="No" sortable={true} style={{ width: '8%' }} />
                                        <Column field="nom_div" filter={true} filterMatchMode='contains' header="Division" sortable={true} style={{ width: '42%' }} />
                                        <Column field="code_dept" filter={true} filterMatchMode='contains' header="Département" sortable={true} style={{ width: '20%' }} />
                                        <Column field="nbh_trav " filter={true} filterMatchMode='contains' header="NBH T" sortable={true} style={{ width: '15%' }} />
                                        <Column field="nbh_retard" header="NBH R" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <div className="card card-w-title" style={{ height: 700 }}>
                            <Chart type="horizontalBar" data={chart_data} height={300} />
                        </div>
                    </div>





                </div>
            </div>
        );
    }
} 