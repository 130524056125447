import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';

export class Form_groupe extends Component {
    constructor() {
        super();
        this.state = {
            liste_groupe: [],
            liste_progh:  [],
            selectedgroupe: null,
            progh: null,
            idGrp: 0,
            nomGrp: '',
            descGrp: '',
            actGrp: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
        };
        this.edit_groupe = this.edit_groupe.bind(this);
        this.conf_delete_groupe = this.conf_delete_groupe.bind(this);
        this.myservice = new Digipointservice();
    }

    componentDidMount() {
        this.myservice.getListe_progh().then(data => this.setState({ liste_progh: data }));
        this.rechliste_groupe();
    }

    rechliste_groupe() {
        this.setState({ showindicator: true });
        this.myservice.getListe_groupe().then(data => this.setState({ liste_groupe: data,showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_groupe();
            this.rechliste_groupe();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_groupe(data) {
        if (data != null && data != undefined) {
            this.setState({ idGrp: data.idGrp });
            this.setState({ nomGrp: data.nomGrp });
            this.setState({ descGrp: data.descGrp });
            this.setState({ modif: true });
            this.setState({ actGrp: data.actGrp });
            this.setState({ selectedgroupe: data });
            window.scrollTo(0, 0);
        }
    }

    annule_groupe() {
        this.setState({ descGrp: '' });
        this.setState({ nomGrp: '' });
        this.setState({ idGrp: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_groupe() {
        if (this.state.nomGrp == ''  || this.state.actGrp == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                this.setState({ showindicator: true });
                let data={idGrp: '0',nomGrp: this.state.nomGrp,descGrp: this.state.descGrp,actGrp: this.state.actGrp};
                this.myservice.enreg_groupe(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                var data=this.state.selectedgroupe;
                data.nomGrp=this.state.nomGrp;
                data.descGrp=this.state.descGrp;
                data.actGrp= this.state.actGrp;
                this.setState({ showindicator: true });
                this.myservice.enreg_groupe(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_groupe(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_groupe(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_groupe() {
        if (this.state.selectedgroupe == null || this.state.selectedgroupe == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.supr_groupe(this.state.selectedgroupe).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_groupe(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedgroupe: data });
            this.setState({ msg: data.nomGrp });
            this.setState({ visible: true });
        }
    }


    render() {
       
        const liste_act = [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' }
        ];
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_groupe();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{height: 600}}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-1">
                                    <label htmlFor="libCodif">Nom groupe</label>
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <InputText  value={this.state.nomGrp} onChange={(e) => this.setState({ nomGrp: e.target.value,descGrp: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-1">
                                    <label >Description</label>
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <InputText  value={this.state.descGrp} onChange={(e) => this.setState({ descGrp: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-1">
                                    <label htmlFor="descCodif">Actif</label>
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown value={this.state.actGrp} options={liste_act} onChange={(e) => { this.setState({ actGrp: e.value }) }} optionLabel="label" style={{ width: 300 }} placeholder="Selectionner" />
                                </div>

                                
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_groupe()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_groupe()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_groupe} paginatorPosition="top" selectionMode="single" paginator={true} rows={20} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedgroupe: e.data })} onRowSelect={e => this.setState({ selectedgroupe: e.data })}
                                responsive={true} selection={this.state.selectedgroupe} >
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="nomGrp"  header="Nom groupe" sortable={true} style={{ width: '40%' }} />
                                <Column field="descGrp"  header="Description" sortable={true} style={{ width: '35%' }} />
                                <Column field="actGrp"  header="Actif" sortable={true} style={{ width: '15%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}