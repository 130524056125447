import React, { Component } from 'react';
import Digipointservice from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { InputTextarea } from 'primereact/inputtextarea';


export class Form_objectif extends Component {
    constructor() {
        super();
        this.state = {
            liste_obj: [],
            liste_pobj: [],
            liste_pers: [],
            liste_lobj: [],
            liste_ligne: [],
            selectedObj: null,
            selectedLigne: null,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            libLobj: '',
            poidsLobj: 0,
            tauxLobj: 0,
            cmtLobj: '',
            indEvalLobj: '',
            selectedLobj: null,

            idObj: 0,
            cmtObj: '',
            idPers: null,
            idPobj: null,
            msg: '',
            modif: false,
            modifLobj: false,
            visible: false,
            visibleLobj: false,
            showindicator: false,

            visible_dlgObj: false,
            visible_dlgObjModif: false,
            visible_dlgLobj: false,
            visible_dlgLobjModif: false
        };
        this.edit_obj = this.edit_obj.bind(this);
        this.conf_delete_obj = this.conf_delete_obj.bind(this);
        this.delete_ligne = this.delete_ligne.bind(this);

        this.edit_lobj = this.edit_lobj.bind(this);
        this.conf_delete_lobj = this.conf_delete_lobj.bind(this);

        this.libLobjEditor = this.libLobjEditor.bind(this);
        this.poidsLobjEditor = this.poidsLobjEditor.bind(this);
        this.tauxLobjEditor = this.tauxLobjEditor.bind(this);
        this.indEvalLobjEditor = this.indEvalLobjEditor.bind(this);
        this.inputTextEditor = this.inputTextEditor.bind(this);
        this.myservice = new Digipointservice();
    }

    libLobjEditor(props) {
        return this.inputTextEditor(props, 'libLobj');
    }
    poidsLobjEditor(props) {
        return this.inputTextEditor(props, 'poidsLobj');
    }
    tauxLobjEditor(props) {
        return this.inputTextEditor(props, 'tauxLobj');
    }
    indEvalLobjEditor(props) {
        return this.inputTextEditor(props, 'indEvalLobj');
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_ligne: updateds });
    }

    inputTextEditor(props, field) {
        return <InputTextarea rows={2} type="text" value={props.rowData[field]} onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }


    componentDidMount() {
        this.rech_liste_objectif();

        this.myservice.getListe_user().then(data => {
            if (data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.idUser, label: x.nomUser + ' ' + x.matrUser }
                });
                this.setState({ liste_pers: liste });
            }
        });

        this.myservice.get_liste_pobj().then(data => {
            if (data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.idPobj, label: x.libPobj }
                });
                this.setState({ liste_pobj: liste });
            }
        });

    }

    rech_liste_objectif() {
        this.myservice.get_liste_obj().then(data => this.setState({ liste_obj: data }));
    }

    getLignesObj(idObj) {
        this.myservice.get_liste_lobj(idObj).then(data => this.setState({ liste_lobj: data }));
    }

    resultat(code, contenu) {
        if (code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_obj();
            this.annule_lobj();
            this.rech_liste_objectif();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_obj(data) {
        if (data != null && data != undefined) {
            this.setState({ idObj: data.idObj });
            this.setState({ cmtObj: data.cmtObj });
            this.setState({ idPobj: data.idPobj.idPobj });
            this.setState({ idPers: data.idPers.idUser })
            this.setState({ modif: true, visible_dlgObjModif: true });
            this.setState({ selectedObj: data });
            window.scrollTo(0, 0);
        }
    }

    edit_lobj(data) {
        if (data != null && data != undefined) {
            this.setState({ libLobj: data.libLobj });
            this.setState({ poidsLobj: data.poidsLobj });

            this.setState({ tauxLobj: data.tauxLobj });
            this.setState({ cmtLobj: data.cmtLobj });
            this.setState({ indEvalLobj: data.indEvalLobj });

            this.setState({
                modifLobj: true,
                visible_dlgLobjModif: true
            });
            this.setState({ selectedLobj: data });
            window.scrollTo(0, 0);
        }
    }


    annule_obj() {
        this.setState({ cmtObj: '' });
        this.setState({ idObj: 0 });
        this.setState({
            modif: false,
            visible_dlgObjModif: false
        });
        this.forceUpdate();
    }

    annule_lobj() {
        this.setState({ libLobj: '' });
        this.setState({ cmtLobj: '' });
        this.setState({ indEvalLobj: '' });
        this.setState({ poidsLobj: 0 });
        this.setState({ tauxLobj: 0 });
        this.setState({
            modifLobj: false,
            visible_dlgLobjModif: false
        });
        this.forceUpdate();
    }

    enreg_obj() {
        if (this.state.idPers == null || this.state.idPobj == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                this.setState({ showindicator: true });
                let obj = { idObj: '0', cmtObj: this.state.cmtObj, idPobj: { idPobj: this.state.idPobj }, idPers: { idUser: this.state.idPers }, iduser: this.state.userconnected.idUser, idEtabl: this.state.userconnected.idEtabl };
                if (this.state.liste_ligne == null || this.state.liste_ligne == undefined) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les objectifs' });
                    return;
                } else if (this.state.liste_ligne.length <= 0) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez ajouter les objectifs' });
                    return;
                }
                for (var i = this.state.liste_ligne.length; i--;) {
                    this.state.liste_ligne[i].idObj = obj;
                    this.state.liste_ligne[i].iduser = this.state.userconnected.idUser;
                }
                this.myservice.add_batch_lobj(this.state.liste_ligne).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selectedObj;
                data.cmtObj = this.state.cmtObj;
                //data.idPers= { idUser: this.state.idPers.value };
                data.idPobj = { idPobj: this.state.idPobj };
                this.setState({ showindicator: true });
                this.myservice.enreg_obj(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    enreg_lobj() {
        if (this.state.libLobj == '' || this.state.tauxLobj == '' || this.state.poidsLobj == '') {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.selectedObj == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Veuillez sélectionner l'objectif " });
        } else {
            if (this.state.modifLobj == false) {
                this.setState({ showindicator: true });
                let data = { idLobj: '0', libLobj: this.state.libLobj, poidsLobj: this.state.poidsLobj, tauxLobj: this.state.tauxLobj, cmtLobj: this.state.cmtLobj, indEvalLobj: this.state.indEvalLobj, idObj: { idObj: this.state.selectedLobj.idObj } };
                this.myservice.enreg_lobj(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                var data = this.state.selectedLobj;
                data.cmtLobj = this.state.cmtLobj;
                data.libLobj = this.state.libLobj;
                data.tauxLobj = this.state.tauxLobj;
                data.poidsLobj = this.state.poidsLobj;
                data.indEvalLobj = this.state.indEvalLobj;
                this.setState({ showindicator: true });
                this.myservice.enreg_lobj(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_obj(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_obj(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -10 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_obj() {
        if (this.state.selectedObj == null || this.state.selectedObj == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_obj(this.state.selectedObj).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_obj(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedObj: data });
            this.setState({ msg: data.idPers.nomUser });
            this.setState({ visible: true });
        }
    }

    actionTemplate_ligne(rowData, column) {
        return <div className="p-grid">
            <div className="p-col"><Button onClick={(e) => this.delete_ligne(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_lobj(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_lobj(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_lobj(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -10 }} icon="pi pi-times" /></div>
        </div>
    }

    add_obj() {
        Array.prototype.insert = function (index) {
            this.splice.apply(this, [index, 0].concat(this.slice.call(arguments, 1)));
        };
        this.state.liste_ligne.insert(0,
            {
                idLobj: '0',
                libLobj: '',
                poidsLobj: 0,
                tauxLobj: 0,
                indEvalLobj: ''
            }
        );
    }


    delete_ligne(data) {
        for (var i = this.state.liste_ligne.length; i--;) {
            if (data.libLobj == this.state.liste_ligne[i].libLobj) {
                this.state.liste_ligne.splice(i, 1);
                break;
            }
        }
        this.forceUpdate();
    }

    delete_lobj() {
        if (this.state.selectedLobj == null || this.state.selectedLobj == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_lobj(this.state.selectedLobj).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_lobj(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedLobj: data });
            this.setState({ msg: data.libLobj });
            this.setState({ visible: true });
        }
    }


    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_obj();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        const dialogFooter_lobj = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visibleLobj: false });
                    this.delete_obj();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visibleLobj: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        var btn_add_ligne = <div style={{ textAlign: 'left', width: 100 }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="Ajouter" onClick={(e) => this.add_obj()} ></Button></div>;
        var btn_add_new = <div style={{ textAlign: 'left', width: 200 }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="Nouvel objectif" onClick={() => this.setState({ visible_dlgObj: true, modif: false })}></Button></div>;

        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visibleLobj} style={{ width: '250px' }} modal={true} footer={dialogFooter_lobj} onHide={(e) => this.setState({ visibleLobj: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Modification" visible={this.state.visible_dlgLobjModif} style={{ width: '400px' }} modal={true} onHide={(e) => this.setState({ visible_dlgLobjModif: false })}>
                    <div className="p-col-12">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-3">
                                <label htmlFor="descCodif">Indicateur *</label>
                            </div>
                            <div className="p-col-12 p-md-9">
                                <InputTextarea value={this.state.libLobj} disabled={true} rows={3} optionLabel='label' onChange={event => this.setState({ libLobj: event.target.value })} />
                            </div>

                            <div className="p-col-12 p-md-3">
                                <label htmlFor="descCodif">Poids *</label>
                            </div>
                            <div className="p-col-12 p-md-9">
                                <InputText value={this.state.poidsLobj} onChange={(e) => this.setState({ poidsLobj: e.target.value })} />
                            </div>
                            <div className="p-col-12 p-md-3">
                                <label htmlFor="descCodif">Taux d'atteinte *</label>
                            </div>
                            <div className="p-col-12 p-md-9">
                                <InputText value={this.state.tauxLobj} onChange={(e) => this.setState({ tauxLobj: e.target.value })} />
                            </div>
                            <div className="p-col-12 p-md-3">
                                <label htmlFor="descCodif">Commentaire</label>
                            </div>
                            <div className="p-col-12 p-md-9">
                                <InputTextarea value={this.state.cmtLobj} rows={3} optionLabel='label' onChange={event => this.setState({ cmtLobj: event.target.value })} />
                            </div>
                        </div>
                    </div>
                    <center>
                        <div className="p-grid" style={{ width: 300 }}>
                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_lobj()} /></div>
                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_lobj()} /></div>
                        </div>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </Dialog>



                <Dialog header="Modification" visible={this.state.visible_dlgObjModif} style={{ width: '400px' }} modal={true} onHide={(e) => this.setState({ visible_dlgObjModif: false })}>
                    <div className="p-col-12">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-3">
                                <label htmlFor="descCodif">Personnel *</label>
                            </div>
                            <div className="p-col-12 p-md-9">
                                <Dropdown options={this.state.liste_pers} disabled={true} placeholder='Choisir un personnel' filter={true} filterMatchMode='contains' filterBy='label' value={this.state.idPers} optionLabel='label' onChange={event => this.setState({ idPers: event.value })} autoWidth={false} />
                            </div>

                            <div className="p-col-12 p-md-3">
                                <label htmlFor="descCodif">Période *</label>
                            </div>
                            <div className="p-col-12 p-md-9">
                                <Dropdown options={this.state.liste_pobj} filter={true} filterMatchMode='contains' filterBy='label' value={this.state.idPobj} optionLabel='label' onChange={event => this.setState({ idPobj: event.value })} autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-3">
                                <label htmlFor="descCodif">Commentaire</label>
                            </div>
                            <div className="p-col-12 p-md-9">
                                <InputTextarea value={this.state.cmtObj} rows={3} optionLabel='label' onChange={event => this.setState({ cmtObj: event.target.value })} />
                            </div>
                        </div>
                    </div>
                    <center>
                        <div className="p-grid" style={{ width: 300 }}>
                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_obj()} /></div>
                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_obj()} /></div>
                        </div>
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </Dialog>

                <Dialog header="Nouvel objectif" visible={this.state.visible_dlgObj} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible_dlgObj: false })}>
                    <div className="card card-w-title" style={{ height: 400 }}>
                        <DataTable header={btn_add_ligne} value={this.state.liste_ligne} responsive={true} selectionMode="single" selection={this.state.selectedObj}
                            onRowClick={e => this.setState({ selectedLigne: e.data })} onRowSelect={e => this.setState({ selectedLigne: e.data })} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                            <Column header="#" body={this.actionTemplate_ligne.bind(this)} style={{ width: '5%' }} editable={true} />
                            <Column field="libLobj" header="Objectif" style={{ width: '35%' }} editor={this.libLobjEditor} />
                            <Column field="poidsLobj" header="Poids" style={{ width: '15%' }} editor={this.poidsLobjEditor} />
                            <Column field="tauxLobj" header="Taux" style={{ width: '15%' }} editor={this.tauxLobjEditor} />
                            <Column field="indEvalLobj" header="Indicateur" style={{ width: '30%' }} editor={this.indEvalLobjEditor} />
                        </DataTable>
                    </div>
                    <div>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-2">
                                <label htmlFor="descCodif">Personnel *</label>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <Dropdown options={this.state.liste_pers} placeholder='Choisir un personnel' filter={true} filterMatchMode='contains' filterBy='label' value={this.state.idPers} optionLabel='label' onChange={event => this.setState({ idPers: event.value })} autoWidth={false} />
                            </div>

                            <div className="p-col-12 p-md-2">
                                <label htmlFor="descCodif">Période *</label>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <Dropdown options={this.state.liste_pobj} filter={true} filterMatchMode='contains' filterBy='label' value={this.state.idPobj} optionLabel='label' onChange={event => this.setState({ idPobj: event.value })} autoWidth={false} />
                            </div>
                        </div>
                        <center>
                            <div className="p-grid" style={{ width: 300 }}>
                                <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_lobj()} /></div>
                                <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_obj()} /></div>
                            </div>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </center>
                    </div>
                </Dialog>


                <div className="p-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 600 }}>
                                    <DataTable value={this.state.liste_obj} responsive={true} ref={(el) => { this.dt = el; }}
                                        paginatorPosition="top" selectionMode="single" paginator={true} rows={10} selection={this.state.selectedObj}
                                        alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedObj: e.data })} onRowSelect={e => this.setState({ selectedObj: e.data }, () => {
                                            this.getLignesObj(this.state.selectedObj.idObj);
                                        })} paginatorLeft={btn_add_new} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                        <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '12%' }} />
                                        <Column field="idPers.nomUser" filter={true} filterMatchMode='contains' header="Personnel" sortable={true} style={{ width: '35%' }} />
                                        <Column field="idPers.idSrv.nomSrv" filter={true} filterMatchMode='contains' header="Service" sortable={true} style={{ width: '35%' }} />
                                        <Column field="idPobj.libPobj" filter={true} filterMatchMode='contains' header="Période" sortable={true} style={{ width: '18%' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-6">
                        <div className="card card-w-title" style={{ height: 600 }}>
                            <DataTable value={this.state.liste_lobj} paginatorPosition="top" selectionMode="single" paginator={true} rows={10}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedEtabl: e.data })} onRowSelect={e => this.setState({ selectedEtabl: e.data })}
                                responsive={true} selection={this.state.selectedEtabl} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                <Column header="Action" body={this.actionTemplate_lobj.bind(this)} style={{ width: '12%' }} />
                                <Column field="libLobj" filter={true} filterMatchMode='contains' header="Objectif" sortable={true} style={{ width: '30%' }} />
                                <Column field="poidsLobj" filter={true} filterMatchMode='contains' header="Poids" sortable={true} style={{ width: '15%' }} />
                                <Column field="tauxLobj" filter={true} filterMatchMode='contains' header="Taux" sortable={true} style={{ width: '13%' }} />
                                <Column field="indEvalLobj" filter={true} filterMatchMode='contains' header="Indicateur" sortable={true} style={{ width: '30%' }} />
                            </DataTable>
                        </div>
                    </div>




                </div>
            </div>
        );
    }
}