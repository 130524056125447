import React from 'react';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { CSSTransition } from 'react-transition-group';
import Digipointservice from './service/Digipointservice';

const AppTopbar = (props) => {

    const onTopbarItemClick = (event, item) => {
        if (props.onTopbarItemClick) {
            props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    };  
 
    const appservice = new Digipointservice();
    var userconnected = appservice.get_userconnected();
 

    var nom_user = '';

    if (userconnected !== null && userconnected !== undefined) {
        nom_user = userconnected.nomUser;
    }
 
    let topbarItemsClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': props.topbarMenuActive });

    return (
        <div className="topbar clearfix">

            <div className="logo-link">
                <img className="logo" alt="apollo-layout" src="assets/layout/images/logo.png" />
            </div>

            <button type="button" className="p-link menu-button" id="menu-button" onClick={props.onMenuButtonClick}>
                <i className="pi pi-align-left"></i>
            </button>

            <button type="button" className="p-link profile" onClick={props.onTopbarMenuButtonClick}>
                <span className="username">{nom_user}</span>
                <img src="assets/layout/images/avatar/avatar.png" alt="apollo-layout" />
                <i className="pi pi-angle-down"></i>
            </button>

          

            <ul className={topbarItemsClassName}>
               
                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'settings' })}
                    onClick={(e) => onTopbarItemClick(e, 'settings')}>
                    <button type="button" className="p-link" onClick={(e) => {
                        window.location = "/#settings";
                    }}>
                        <i className="topbar-icon pi pi-fw pi-user-edit"></i>
                        <span className="topbar-item-name">Changer mot de passe</span>
                    </button>
                </li>
                
                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'messages' })}
                    onClick={(e) => onTopbarItemClick(e, 'messages')}>
                    <button type="button" className="p-link" onClick={(e) => {
                        window.location = "/#inbox";
                    }}>
                        <i className="topbar-icon pi pi-fw pi-envelope"></i>
                        <span className="topbar-item-name">Messages</span>
                        <span className="topbar-badge">25</span>
                    </button>
                </li>
                
                <li className={classNames({ 'menuitem-active': props.activeTopbarItem === 'profile' })}
                    onClick={(e) => onTopbarItemClick(e, 'profile')}>
                    <button type="button" className="p-link" onClick={(e) => {
                        localStorage.removeItem('userconnected');
                        window.location = "/#login";
                    }}>
                        <i className="topbar-icon pi pi-fw pi-sign-out"></i>
                        <span className="topbar-item-name">Déconnexion</span>
                    </button>
                </li>

            </ul>
        </div>
    );

}

export default AppTopbar;
