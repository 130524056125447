import React, { Component } from 'react';
import Digipointservice from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputMask } from 'primereact/inputmask';

export class Form_horaire extends Component {
    constructor() {
        super();
        this.state = {
            liste_horaire: [],
            liste_progh: [],
            selectedHoraire: null,
            selectedProgh: null,
            libProgHor: '',
            descProgHor: '',
            arvHor: '',
            depHor: '',
            jourHor: { label: 'Lundi', value: 'Lundi', numJour: 1 },
            msg: '',
            modif_horaire: false,
            modif_progh: false,
            showindicator: false,
            visible: false
        };
        this.edit_horaire = this.edit_horaire.bind(this);
        this.conf_delete_horaire = this.conf_delete_horaire.bind(this);
        this.edit_progh = this.edit_progh.bind(this);
        this.conf_delete_progh = this.conf_delete_progh.bind(this);
        this.myservice = new Digipointservice();
    }

    get_num() {
        let nb = 1;
        if (this.state.jourHor == 'Lundi') {
            nb = 1;
        } else if (this.state.jourHor == 'Mardi') {
            nb = 2;
        } else if (this.state.jourHor == 'Mercredi') {
            nb = 3;
        } else if (this.state.jourHor == 'Jeudi') {
            nb = 4;
        } else if (this.state.jourHor == 'Vendredi') {
            nb = 5;
        } else if (this.state.jourHor == 'Samedi') {
            nb = 6;
        } else if (this.state.jourHor == 'Dimanche') {
            nb = 7;
        }
    }

    componentDidMount() {
        this.setState({ showindicator: true });
        this.myservice.getListe_progh().then(data => this.setState({ liste_progh: data, showindicator: false }));
    }

    get_liste_horaire() {
        this.setState({ showindicator: true });
        this.myservice.getListe_horaire(this.state.selectedProgh.idProgHor + '').then(data => this.setState({ liste_horaire: data, showindicator: false }));
    }
    resultat(code, contenu) {
        if (code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.componentDidMount();
            this.annule_progh();
            this.annule_horaire();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_horaire(data) {
        if (data != null && data != undefined) {
            this.setState({ arvHor: data.arvHor.split(" ")[1] });
            this.setState({ depHor: data.depHor.split(" ")[1] });
            this.setState({ modif_horaire: true });
            this.setState({ jourHor: data.jourHor });
            this.setState({ selectedHoraire: data });
        }
    }

    edit_progh(data) {
        if (data != null && data != undefined) {
            this.setState({ libProgHor: data.libProgHor });
            this.setState({ descProgHor: data.descProgHor });
            this.setState({ modif_progh: true });
            this.setState({ selectedProgh: data });
            window.scrollTo(0, 0);
        }
    }

    annule_horaire() {
        this.setState({ arvHor: '' });
        this.setState({ depHor: '' });
        this.setState({ modif_horaire: false });
        this.forceUpdate();
    }

    annule_progh() {
        this.setState({ libProgHor: '' });
        this.setState({ descProgHor: '' });
        this.setState({ modif_progh: false });
        this.forceUpdate();
    }
 
    enreg_horaire() {
        if (this.state.arvHor == "" || this.state.depHor == "" || this.state.selectedProgh == null || this.state.selectedProgh == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            var arv = this.state.arvHor + '';
            var dep = this.state.depHor + '';

            arv = '01/01/2019 ' + arv;

            dep = '01/01/2019 ' + dep;

            if (this.state.modif_horaire == false) {
                this.setState({ showindicator: true });
                let data = { idHor: '0', jourHor: this.state.jourHor, arvHor: arv, depHor: dep, idProgHor: { idProgHor: this.state.selectedProgh.idProgHor },njourHor: this.get_num() };
                this.myservice.enreg_horaire(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                this.setState({ showindicator: true });
                var data = this.state.selectedHoraire;
                data.jourHor = this.state.jourHor;
                data.njourHor = this.get_num();
                data.arvHor = arv;
                data.depHor = dep;
                this.myservice.enreg_horaire(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    enreg_progh() {
        if (this.state.libProgHor == "" || this.state.descProgHor == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif_progh == false) {
                this.setState({ showindicator: true });
                let data = { idProgHor: '0', libProgHor: this.state.libProgHor, descProgHor: this.state.descProgHor };
                this.myservice.enreg_progh(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                this.setState({ showindicator: true });
                var data = this.state.selectedProgh;
                data.libProgHor = this.state.libProgHor;
                data.descProgHor = this.state.descProgHor;
                this.myservice.enreg_progh(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    actionTemplate_horaire(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_horaire(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_horaire(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_progh(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_progh(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_progh(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_horaire() {
        if (this.state.selectedHoraire == null || this.state.selectedHoraire == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.supr_horaire(this.state.selectedHoraire).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_horaire(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedHoraire: data });
            this.setState({ msg: data.JOUR_HOR });
            this.setState({ visible: true });
        }
    }

    delete_progh() {
        if (this.state.selectedProgh == null || this.state.selectedProgh == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.supr_progh(this.state.selectedProgh.ID_PROG_HOR).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_progh(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedProgh: data });
            this.setState({ msg: data.LIB_PROG_HOR });
            this.setState({ visible: true });
        }
    }


    render() {
        const liste_jour = [
            { label: 'Lundi', value: 'Lundi', numJour: 1 },
            { label: 'Mardi', value: 'Mardi', numJour: 2 },
            { label: 'Mercredi', value: 'Mercredi', numJour: 3 },
            { label: 'Jeudi', value: 'Jeudi', numJour: 4 },
            { label: 'Vendredi', value: 'Vendredi', numJour: 5 },
            { label: 'Samedi', value: 'Samedi', numJour: 6 },
            { label: 'Dimanche', value: 'Dimanche', numJour: 7 }
        ];
        const dialogFooter_horaire = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_horaire();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooter_progh = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_horaire();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter_horaire} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter_progh} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <div className="p-grid">

                    <div className="p-col-12 p-lg-5">
                        <div className="card card-w-title" style={{ height: 600 }}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6 p-lg-6">
                                            <InputText value={this.state.libProgHor} placeholder='Libelle' onChange={(e) => this.setState({ libProgHor: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-6 p-lg-6">
                                            <InputText value={this.state.descProgHor} placeholder='Description' onChange={(e) => this.setState({ descProgHor: e.target.value })} />
                                        </div>
                                    </div>

                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_progh()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_progh()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                    <DataTable value={this.state.liste_progh} paginatorPosition="top" selectionMode="single" paginator={true} rows={100}
                                        alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedProgh: e.data })}
                                        onRowSelect={e => {
                                            this.setState({ selectedProgh: e.data }, () => {
                                                this.get_liste_horaire();
                                            });
                                        }
                                        } className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                        responsive={true} selection={this.state.selectedProgh} >
                                        <Column header="Action" body={this.actionTemplate_progh.bind(this)} style={{ width: '20%' }} />
                                        <Column field="libProgHor" header="Libelle" sortable={true} style={{ width: '40%' }} />
                                        <Column field="descProgHor" header="Description" sortable={true} style={{ width: '40%' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="p-col-12 p-lg-7">

                        <div className="card card-w-title" style={{ height: 600 }}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <Dropdown value={this.state.jourHor} options={liste_jour} onChange={(e) => { this.setState({ jourHor: e.value }) }} optionLabel="label" style={{ width: 200 }} placeholder="Jour" />
                                        </div>
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <InputMask  mask="99:99:99" value={this.state.arvHor} placeholder="Heure arrivé" onChange={(e) => this.setState({ arvHor: e.value })} ></InputMask>
                                        </div>
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <InputMask  mask="99:99:99" value={this.state.depHor} placeholder="Heure départ" onChange={(e) => this.setState({ depHor: e.value })} ></InputMask>
                                        </div>
                                    </div>

                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_horaire()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_horaire()} /></div>
                                        </div>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </center>

                                    <DataTable value={this.state.liste_horaire} paginatorPosition="top" selectionMode="single" paginator={true} rows={100}
                                        alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedHoraire: e.data })} onRowSelect={e => this.setState({ selectedHoraire: e.data })}
                                        responsive={true} selection={this.state.selectedHoraire} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                        <Column header="Action" body={this.actionTemplate_horaire.bind(this)} style={{ width: '15%' }} />
                                        <Column field="jourHor" header="Jour" sortable={true} style={{ width: '45%' }} />
                                        <Column field="arvHor" body={(rowData, column) => {
                                            let h = '';
                                            if (rowData.arvHor !== undefined && rowData.arvHor !== null) {
                                                h = rowData.arvHor.split(" ")[1];
                                            }
                                            return <div>
                                                <span style={{ color: '#000' }}>{h}</span>
                                            </div>
                                        }} header="Arrivé" sortable={true} style={{ width: '20%' }} />
                                        <Column field="depHor" body={(rowData, column) => {
                                            let h = '';
                                            if (rowData.depHor !== undefined && rowData.depHor !== null) {
                                                h = rowData.depHor.split(" ")[1];
                                            }
                                            return <div>
                                                <span style={{ color: '#000' }}>{h}</span>
                                            </div>
                                        }} header="Départ" sortable={true} style={{ width: '20%' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}