import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chart } from 'primereact/chart';

export class Form_tranche_retard extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rid_user: '',
            liste_loc: [],
            liste_point: [],
            liste_user: [],
            site: null,
            user: null,
            showindicator: false,
            ptranche: 1
        };
        this.export = this.export.bind(this);
        this.myservice = new Digipointservice();
        this.state.pd1.setHours(0, 0, 0);
    }

    componentDidMount() {
        this.get_liste_point();
    }

    get_liste_point() {
        this.setState({ showindicator: true });
        var spd1 = moment(this.state.pd1).format('DD/MM/YYYY H:mm:ss');
        var spd2 = moment(this.state.pd2).format('DD/MM/YYYY H:mm:ss');
        this.myservice.st_trache_retard(spd1, spd2,this.state.ptranche).then(data => this.setState({ liste_point: data, showindicator: false }));
    }

    export() {
        this.dt.exportCSV();
    }

    render() {

        let liste_label = null;
        let liste_ds_retard = null;
        if (this.state.liste_point != null && this.state.liste_point != undefined && this.state.liste_point.length > 0) {
            liste_label = this.state.liste_point.map(x => {
                return x.label
            });

            liste_ds_retard = this.state.liste_point.map(x => {
                return x.ivalue
            });


        }

        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    label: 'Tranches retards',
                    backgroundColor: '#FC5146',
                    data: liste_ds_retard
                }
            ]
        };

        return (

            <div className="p-fluid">

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 700 }}>
                            <div className="p-col-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} showTime={true} showSeconds={true} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} showTime={true} showSeconds={true} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <InputText placeholder='Tranche en heure' keyfilter="int" value={this.state.ptranche} onChange={(e) => this.setState({ ptranche: e.target.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_point()} />
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '25px', height: '25px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                            </div>

                            <Chart type="bar" data={chart_data}  height="120w"/>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}