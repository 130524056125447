import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import './styles.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { Menu } from 'primereact/menu';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';

export class Form_absence extends Component {
    constructor() {
        super();
        this.state = {
            liste_abs: [],
            liste_users: [],
            liste_process: [],
            selectedabs: null,
            disable_modif: false,
            disable_annuler: false,
            disable_supr: false,
            disable_valider: false,
            idUser: null,
            dateDebutAbs: '',
            dateFinAbs: '',
            id_abs: 0,
            motifAbs: '',
            typeAbs: null,
            typeAbs_for_rech: null,
            ruser_for_rech: null,
            pd1: new Date(),
            pd2: new Date(),
            rid_user: '',
            rtypeAbs: '',
            showindicator: false,
            liste_typeAbs: [
                { label: 'Congé', value: 'Congé' },
                { label: 'Permission', value: 'Permission' },
                { label: 'Non justifié', value: 'Non justifié' }
            ], 
            menu_items: [
                { label: 'Modifier', icon: 'pi pi-fw pi-pencil', command: () => { this.edit_abs(this.state.selectedabs) } },
                { label: 'Supprimer', icon: 'pi pi-fw pi-trash', command: () => { this.conf_delete_abs(this.state.selectedabs) } },
                { label: 'Valider', icon: 'pi pi-fw pi-check', command: () => { this.update_etat_abs('Validé') } },
                { label: 'Annuler', icon: 'pi pi-fw pi-replay', command: () => { this.update_etat_abs('Annulé') } },
                { label: 'Rejeter', icon: 'pi pi-fw pi-times', command: () => { this.update_etat_abs('Rejeté') } },
            ],
            msg: '',
            modif: false,
            visible: false,
            file: null,
            cmt_proc: '',
            visible_process: false,
            selectedprocess: null,
            etat_proc: '',
            userconnected: null,

        };
        this.setState({ userconnected: JSON.parse(localStorage.getItem('userconnected')) });
        this.edit_abs = this.edit_abs.bind(this);
        this.update_etat_abs=this.update_etat_abs.bind(this);
        this.conf_delete_abs = this.conf_delete_abs.bind(this);
        this.myservice = new Digipointservice();

    }

    get_liste_process() {
        this.myservice.getListe_process_abs(this.state.selectedabs.idAbs + '').then(data => this.setState({ liste_process: data }));
    }

    componentDidMount() {
        this.myservice.getListe_user().then(data => this.setState({ liste_users: data }));
        this.get_liste_abs();
    }

    get_liste_abs() {
       
        this.setState({ showindicator: true });
        var spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        var spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        let ruser = 'Tous';
        let rtype = 'Tous';
        if (this.state.rid_user != undefined && this.state.rid_user != null) {
            ruser = this.state.rid_user.value;
        }
        if (this.state.rtypeAbs != undefined && this.state.rtypeAbs != null) {
            rtype = this.state.rtypeAbs.value;
        }
        this.myservice.getListe_abs(spd1, spd2, ruser, rtype).then(data => this.setState({ liste_abs: data, showindicator: false }));
    }
 
    resultat(code, contenu) {
        if (code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_abs();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_abs(data) {
        if (data != null && data != undefined) {
            this.setState({ idAbs: data.idAbs });
            this.setState({ typeAbs:  data.typeAbs });
            this.setState({ idUser: data.idUser.idUser});
            this.setState({ dateDebutAbs: new Date(moment(data.dateDebutAbs,'DD/MM/YYYY').format('DD/MM/YYYY')) });   
            this.setState({ dateFinAbs: new Date(moment(data.dateFinAbs,'DD/MM/YYYY').format('DD/MM/YYYY')) });   
            this.setState({ motifAbs: data.motifAbs });
            this.setState({ modif: true });
            this.setState({ selectedabs: data });
            window.scrollTo(0, 0);
        }
    }

    annule_abs() {
        this.setState({ motifAbs: '' });
        this.setState({ id_abs: 0 });
        this.setState({ dateDebutAbs: '' });
        this.setState({ dateFinAbs: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    annule_process() {
        this.setState({ cmt_proc: '' });
        this.setState({ visible_process: false });
    }


    enreg_process() {
        if (this.state.selectedabs == null || this.state.selectedabs == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.enreg_process_abs(this.state.selectedprocess.idProc, this.state.etat_proc, this.state.cmt_proc, '0', this.state.selectedabs.idAbs).then(data => this.resultat(data.code, data.contenu));
        }
    }
 
    enreg_abs() {
        if (this.state.dateDebutAbs == "" || this.state.dateFinAbs == "" || this.state.motifAbs == "" || this.state.typeAbs == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            var deb = moment(this.state.dateDebutAbs).format('DD/MM/YYYY');
            var fin = moment(this.state.dateFinAbs).format('DD/MM/YYYY');
            if (this.state.modif == false) {
                let data = { idAbs: 0, typeAbs: this.state.typeAbs, dateDebutAbs: deb, dateFinAbs: fin, motifAbs: this.state.motifAbs,etatAbs: 'En attente',idUser: {idUser: this.state.idUser} };
                this.myservice.enreg_abs(data).then(data => {
                    if (data != undefined && data != null) {
                        this.resultat(data.code, data.contenu);
                    }
                });
            } else {
                let data = this.state.selectedabs;
                data.motifAbs = this.state.motifAbs;
                data.typeAbs = this.state.typeAbs;
                data.dateDebutAbs = moment(this.state.dateDebutAbs).format('DD/MM/YYYY');
                data.dateFinAbs = moment(this.state.dateFinAbs).format('DD/MM/YYYY');
                this.myservice.enreg_abs(data).then(data => {
                    {
                        if (data != undefined && data != null) {
                            this.resultat(data.code, data.contenu);
                        }
                    }
                });
            }
        }
    }

    actionTemplate(rowData, column) {
        let disable = false;
        if (rowData.disable_modif == 'true') {
            disable = true;
        }
        return <div>
            <Button disabled={disable} label="..." icon="pi pi-bars" onClick={(event) => this.menu.toggle(event)} />
        </div>
    }

    conf_update_etat_process(data, etat) {
        if (data != null && data != undefined) {
            this.setState({ etat_proc: etat });
            this.myservice.getListe_process_by_abs(data.idAbs).then(dataproc => {
                this.setState({ liste_process: dataproc }, () => {
                    for (var i = this.state.liste_process.length; i--;) {

                    }
                });
            }
            );
            this.setState({ visible_process: true });
            this.setState({ selectedabs: data });
        }
    }

    update_etat_abs(etat) {
        if (this.state.selectedabs == null || this.state.selectedabs == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à modifier' });
        } else {
            this.setState({ showindicator: true });
            let data = this.state.selectedabs;
            data.etatAbs=etat;
            this.myservice.enreg_abs(data).then(data => this.resultat(data.code, data.contenu));
        }
    }

    delete_abs() {
        if (this.state.selectedabs == null || this.state.selectedabs == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.supr_abs(this.state.selectedabs).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_abs(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedabs: data });
            this.setState({ msg: data.ordre });
            this.setState({ visible: true });
        }
    }


    render() {
        let luser = null;
        let luser_rech = [{ value: '0', label: 'Tous' }];
        if (this.state.liste_users != null && this.state.liste_users != undefined && this.state.liste_users.length > 0) {
            luser = this.state.liste_users.map(x => {
                return { value: x.idUser, label: x.nomUser + ' ' + x.matrUser }
            });
            luser_rech = luser_rech.concat(luser);
        }
        let liste_typeAbs_rech = [{ value: '0', label: 'Tous les types' }].concat(this.state.liste_typeAbs);
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_abs();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Menu model={this.state.menu_items} popup={true} ref={el => this.menu = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer la ligne N° <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Validation" visible={this.state.visible_process} style={{ width: '850px' }} modal={true} onHide={(e) => this.setState({ visible_process: false })}>

                    <div className="p-grid">
                        <div className="p-col-12">

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="loginuser">Commentaire *</label>
                                </div>
                                <div className="p-col-12 p-md-10">
                                    <InputTextarea rows={2} cols={800} value={this.state.cmt_proc} onChange={(e) => this.setState({ cmt_proc: e.target.value })} autoResize={false} />
                                </div>
                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_process()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_process()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}

                            </center>

                            <DataTable value={this.state.liste_process} paginatorPosition="top" selectionMode="single" paginator={true} rows={5}
                                alwaysShowPaginator={false} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                responsive={true}  >
                                <Column field="dateEtatProc_format" header="Date" style={{ width: '11%' }} />
                                <Column field="user_resp_proc.nomUser" header="Responsable" style={{ width: '32%' }} />
                                <Column field="libProc" header="Libelle" style={{ width: '15%' }} />
                                <Column field="cmtProc" header="Commentaire" style={{ width: '30%' }} />
                                <Column field="etatProc" header="Statut" style={{ width: '12%' }} />
                            </DataTable>
                        </div>
                    </div>
                </Dialog>


                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 1200 }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Personnel *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={luser} placeholder='Choisir un personnel' filter={true} filterMatchMode='contains' filterBy='label' value={this.state.idUser} optionLabel='label' onChange={event => this.setState({ idUser: event.value})} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Motif absence *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_typeAbs} filter={true} filterMatchMode='contains' filterBy='label' value={this.state.typeAbs} optionLabel='label' onChange={event => this.setState({ typeAbs: event.value})} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Observation * </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="libCodif" value={this.state.motifAbs} onChange={(e) => this.setState({ motifAbs: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Date début</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.dateDebutAbs} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateDebutAbs: e.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Date fin</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.dateFinAbs} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateFinAbs: e.value })} />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_abs()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_abs()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}

                            </center>

                            <div className="p-col-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-2">
                                        <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Dropdown options={liste_typeAbs_rech} placeholder='Choisir un type' filter={true} filterMatchMode='contains' filterBy='label' value={this.state.typeAbs_for_rech} optionLabel='label' onChange={event => this.setState({ typeAbs_for_rech: event.value, rtypeAbs: event.value.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Dropdown options={luser_rech} placeholder='Choisir une personne' filter={true} filterMatchMode='contains' filterBy='label' value={this.state.ruser_for_rech} optionLabel='label' onChange={event => this.setState({ ruser_for_rech: event.value, rid_user: event.value.value })} autoWidth={false} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_abs()} />
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_abs} paginatorPosition="top" selectionMode="single" paginator={true} rows={20}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedabs: e.data })} onRowSelect={e => this.setState({ selectedabs: e.data })}
                                responsive={true} selection={this.state.selectedabs} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '6%' }} />
                                <Column field="dateAbs" filter={true} filterMatchMode='contains' header="Date" sortable={true} style={{ width: '8%' }} />
                                <Column field="typeAbs" filter={true} filterMatchMode='contains' header="Motif" sortable={true} style={{ width: '10%' }} />
                                <Column field="idUser.nomUser" filter={true} filterMatchMode='contains' header="Nom" sortable={true} style={{ width: '22%' }} />
                                <Column field="idUser.matrUser" filter={true} filterMatchMode='contains' header="Matricule" sortable={true} style={{ width: '10%' }} />
                                <Column field="idUser.idSrv.nomSrv" header="Service" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '16%' }} />
                                <Column field="dateDebutAbs" filter={true} filterMatchMode='contains' header="Date début" sortable={true} style={{ width: '10%' }} />
                                <Column field="dateFinAbs" filter={true} filterMatchMode='contains' header="Date fin" sortable={true} style={{ width: '10%' }} />
                                <Column field="etatAbs" filter={true} filterMatchMode='contains' header="Etat" sortable={true} style={{ width: '8%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}