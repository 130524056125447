import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
export class Form_etablissement extends Component {
    constructor() {
        super();
        this.state = {
            liste_etabl: [],
            selectedEtabl: null,
            d_delaiEtabl: null,
            pays: null,
            idPays: 1315232,
            idEtabl: 0,
            nomEtabl: '',
            codeEtabl: '',
            emailEtabl: '',
            domaine_etabl: '',
            adrEtabl: '',
            telEtabl: '',
            logoEtabl: '',
            delaiEtabl: '',
            etatEtabl: { label: 'Actif', value: 'Actif' },
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
            file: null
        };
        this.edit_etabl = this.edit_etabl.bind(this);
        this.conf_delete_etabl = this.conf_delete_etabl.bind(this);
        this.myservice = new Digipointservice();

        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)

    }

    fileUpload(file, namefile) {
        this.myservice.upload_file(file,namefile,'photos').then(data => {
            if (data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Logo chargé avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload' });
            }
           });
    }

    onChange(e) {
        this.setState({ file: e.target.files[0] })
    }


    componentDidMount() {
        this.setState({ showindicator: true });
        this.myservice.getListe_etabl().then(data => this.setState({ liste_etabl: data, showindicator: false  }));
    }

    resultat(code, contenu) {
        if (code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_etabl();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_etabl(data) {
        if (data != null && data != undefined) {
            this.setState({ idEtabl: data.idEtabl });
            this.setState({ nomEtabl: data.nomEtabl });
            this.setState({ codeEtabl: data.codeEtabl });
            this.setState({ telEtabl: data.telEtabl });
            this.setState({ adrEtabl: data.adrEtabl });
            this.setState({ emailEtabl: data.emailEtabl });
            this.setState({ logoEtabl: data.logoEtabl });
            this.setState({ idPays: data.idPays });
            this.setState({ delaiEtabl: new Date(moment(data.delaiEtabl,'DD/MM/YYYY').format('DD/MM/YYYY')) });   
            this.setState({ modif: true });
            this.setState({ etatEtabl:  data.etatEtabl });
            this.setState({ selectedEtabl: data });
            window.scrollTo(0, 0);
        }
    }

    annule_etabl() {
        this.setState({ codeEtabl: '' });
        this.setState({ nomEtabl: '' });
        this.setState({ idEtabl: 0 });
        this.setState({ telEtabl: '' });
        this.setState({ logoEtabl: 'nologo.png' });
        this.setState({ emailEtabl: '' });
        this.setState({ adrEtabl: '' });
        this.setState({ delaiEtabl: '' });   
        this.setState({ modif: false });
        this.setState({ file: null });
        this.forceUpdate();
    }

    enreg_etabl() {
        if (this.state.codeEtabl == "" || this.state.nomEtabl == "" || this.state.telEtabl == "" || this.state.etatEtabl == null || this.state.etatEtabl == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {

            let upload=false;
            let nomfic = this.state.logoEtabl;
            if (this.state.file !== null && this.state.file !== undefined) {
                let name = this.state.file.name;
                nomfic = this.state.codeEtabl+ '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload=true;
            }
            let delaiEtabl= moment(this.state.delaiEtabl).format('DD/MM/YYYY');
            if (this.state.modif == false) {
                this.setState({ showindicator: true });
                let data = {idEtabl: 0, nomEtabl: this.state.nomEtabl, codeEtabl: this.state.codeEtabl, telEtabl: this.state.telEtabl, etatEtabl: this.state.etatEtabl, emailEtabl: this.state.emailEtabl, idPays: this.state.idPays,delaiEtabl: delaiEtabl };
                this.myservice.enreg_etabl(data).then(data => {
                    if (data !== undefined && data !== null) {
                        if(upload===true){
                            this.fileUpload(this.state.file, nomfic).then((response) => {
                                console.log(response.data);
                            })
                        }
                        this.resultat(data.code, data.contenu);
                    }
                });
            } else {
                this.setState({ showindicator: true });
                let data=this.state.selectedEtabl;
                data.codeEtabl=this.state.codeEtabl;
                data.nomEtabl=this.state.nomEtabl;
                data.telEtabl=this.state.telEtabl;
                data.emailEtabl=this.state.emailEtabl;
                data.etatEtabl=this.state.etatEtabl;
                data.adrEtabl=this.state.adrEtabl;
                data.delaiEtabl=delaiEtabl;
                this.myservice.enreg_etabl(data).then(data => {
                    {
                        if (data !== undefined && data !== null) {
                            if(upload===true){
                                this.fileUpload(this.state.file, nomfic).then((response) => {
                                    console.log(response.data);
                                })
                            }
                            this.resultat(data.code, data.contenu);
                        }
                    }
                });
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_etabl(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_etabl(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }
 

    delete_etabl() {
        if (this.state.selectedEtabl == null || this.state.selectedEtabl == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.supr_etabl(this.state.selectedEtabl).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_etabl(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedEtabl: data });
            this.setState({ msg: data.nomEtabl });
            this.setState({ visible: true });
        }
    }
 

    render() {
        const liste_etat = [
            { label: 'Actif', value: 'Actif' },
            { label: 'Non actif', value: 'Non actif' }
        ];
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_etabl();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 800 }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Nom </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="libCodif" value={this.state.nomEtabl} onChange={(e) => this.setState({ nomEtabl: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Code</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="descCodif" value={this.state.codeEtabl} onChange={(e) => this.setState({ codeEtabl: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Etat</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.etatEtabl} options={liste_etat} onChange={(e) => { this.setState({ etatEtabl: e.value }) }} optionLabel="label"  placeholder="Selectionner" />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Téléphone</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="descCodif" value={this.state.telEtabl} onChange={(e) => this.setState({ telEtabl: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Email</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="descCodif" value={this.state.emailEtabl} onChange={(e) => this.setState({ emailEtabl: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Adresse</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="descCodif" value={this.state.adrEtabl} onChange={(e) => this.setState({ adrEtabl: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Délai</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.delaiEtabl} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ delaiEtabl: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Logo</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <input type="file" onChange={this.onChange} />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_etabl()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_etabl()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_etabl} paginatorPosition="top" selectionMode="single" paginator={true} rows={10}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedEtabl: e.data })} onRowSelect={e => this.setState({ selectedEtabl: e.data })}
                                responsive={true} selection={this.state.selectedEtabl} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="nomEtabl" filter={true} filterMatchMode='contains' header="Nom" sortable={true} style={{ width: '30%' }} />
                                <Column field="codeEtabl" filter={true} filterMatchMode='contains' header="Code" sortable={true} style={{ width: '10%' }} />
                                <Column field="telEtabl" filter={true} filterMatchMode='contains' header="Telephone" sortable={true} style={{ width: '18%' }} />
                                <Column field="etatEtabl" filter={true} filterMatchMode='contains' header="Etat" sortable={true} style={{ width: '6%' }} />
                                <Column field="delaiEtabl" filter={true} filterMatchMode='contains' header="Délai" sortable={true} style={{ width: '8%' }} />
                                <Column field="emailEtabl" filter={true} filterMatchMode='contains' header="Email" sortable={true} style={{ width: '20%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}