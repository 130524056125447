import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import './styles.css';
import {ProgressSpinner} from 'primereact/progressspinner';

export class Form_edition_pointage extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rid_user: '',
            liste_loc: [],
            liste_point: [],
            liste_user: [],
            site: null,
            user: null,
            showindicator: false
        };
        this.export = this.export.bind(this);
        this.myservice = new Digipointservice();

        this.state.pd1.setHours(0,0,0);
        //import Workbook from 'react-excel-workbook'
    }

    componentDidMount() {
        this.get_liste_point();
    }

    get_liste_point() {
        this.setState({showindicator: true});
        var spd1 = moment(this.state.pd1).format('DD/MM/YYYY H:mm:ss');
        var spd2 = moment(this.state.pd2).format('DD/MM/YYYY H:mm:ss');
        this.myservice.liste_edition_pointage(spd1, spd2, this.state.rid_user).then(data => this.setState({ liste_point: data ,showindicator: false}));
    }

    export() {
        this.dt.exportCSV();
    }

    render() {
       
        let luser = null;

        if (this.state.liste_user != null && this.state.liste_user != undefined && this.state.liste_user.length > 0) {
            let l1 = this.state.liste_user.map(x => {
                return { value: x.idUser, label: x.nomUser + ' ' + x.matrUser }
            });
            let l2 = [{ value: '0', label: 'Tous' }];
            luser = l2.concat(l1);
        }

        var header = <div style={{textAlign:'left', width: 120}}><Button type="button" icon="pi pi-external-link" iconPos="left" label="Exporter" onClick={this.export}></Button></div>;

        return (
            <div className="p-fluid">
                
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <div className="p-col-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} showTime={true} showSeconds={true} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} showTime={true} showSeconds={true} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Dropdown options={luser} placeholder='Choisir une personne' filter={true} filterMatchMode='contains' filterBy='label' value={this.state.user} optionLabel='label' onChange={event => this.setState({ user: event.value, rid_user: event.value.value })} autoWidth={false} panelStyle={{width: 400}}/>
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_point()} />
                                    </div>
                                    <div className="p-col-12 p-md-1">
                                        {this.state.showindicator ? <ProgressSpinner style={{width: '30px', height: '30px'}} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s"/> : null}
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.liste_point} paginatorPosition="top" selectionMode="single" paginator={true} rows={20} paginatorLeft={header} style={{minHeight: '600px'}}
                                alwaysShowPaginator={false}  responsive={true}   ref={(el) => { this.dt = el; }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                <Column field="ordre" header="No" sortable={true} style={{ width: '4%' }} />
                                <Column field="datePoint" header="Date" style={{ width: '7%' }} />
                                <Column field="nom_user" filter={true} filterMatchMode='contains' header="Personnel" sortable={true} style={{ width: '22%' }} />
                                <Column field="matr_user" header="Matricule" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="nom_srv" header="Service" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                                <Column field="heureArv" filter={true} filterMatchMode='contains' header="Arrivé" sortable={true} style={{ width: '8%' }} />
                                <Column field="heureDep" filter={true} filterMatchMode='contains' header="Départ" sortable={true} style={{ width: '8%' }} />
                                <Column field="nbh_trav" filter={true} filterMatchMode='contains' header="NBH T" sortable={true} style={{ width: '7%' }} />
                                <Column field="nbh_retard" header="NBH R" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                                <Column field="nbh_manquant" header="NBH M" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                                <Column field="nbh_suplem" header="NBH S" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}