import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import './styles.css';
import geolib from 'geolib';
import { ProgressSpinner } from 'primereact/progressspinner';

export class Form_pointage extends Component {
    constructor() {
        super();
        this.state = {
            cmt_point: '',
            rid_user: '',
            nom_loc: '',
            id_point: 0,
            lib_point: null,
            idUser: null,
            pd1: new Date(),
            pd2: new Date(),
            id_loc: 0,
            liste_loc: [],
            liste_point: [],
            liste_user: [],
            liste_lib_point: [
                { label: 'Bureau', value: 'Bureau' },
                { label: 'Mission', value: 'Mission' },
                { label: 'Intervention', value: 'Intervention' },
                { label: 'Formation', value: 'Formation' },
                { label: 'Astreinte', value: 'Astreinte' },
                { label: 'Rattrapage', value: 'Rattrapage' }
            ],
            selectedpoint: null,
            site: null,
            user: null,
            msg: '',
            modif: false,
            visible: false,
            visible_update_etat: false,
            showindicator: false
        };
        this.edit_point = this.edit_point.bind(this);
        this.conf_delete_point = this.conf_delete_point.bind(this);
        this.myservice = new Digipointservice();
        this.state.pd1.setHours(0, 0, 0);
    }


    componentDidMount() {
        this.myservice.getListe_user().then(data => this.setState({ liste_user: data }));
        this.myservice.get_liste_localite().then(data => this.setState({ liste_loc: data }));
        this.get_liste_point();
        //this.get_location();
    }

    get_liste_point() {
        this.setState({ showindicator: true });
        var spd1 = moment(this.state.pd1).format('DD/MM/YYYY H:mm:ss');
        var spd2 = moment(this.state.pd2).format('DD/MM/YYYY H:mm:ss');
        let ruser = 'Tous';
        if (this.state.rid_user != undefined && this.state.rid_user != null) {
            ruser = this.state.rid_user.value;
        }
        this.myservice.get_liste_point(spd1, spd2, ruser).then(data => this.setState({ liste_point: data, showindicator: false }));
    }

    get_location() {
        const pointation = window.navigator && window.navigator.geopointation
        if (pointation) {
            pointation.getCurrentPosition((position) => {
                this.setState({ latitude_point: position.coords.latitude, longitude_point: position.coords.longitude });
                //alert(position.coords.latitude+' , '+position.coords.longitude)
            }, (error) => {
                this.setState({ latitude_point: '0', longitude_point: '0' })
            })
        }
    }


    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_point();
            this.setState({ showindicator: false });
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
            this.setState({ showindicator: false });
        }
    }

    edit_point(data) {
        if (data != null && data != undefined) {
            this.setState({ id_point: data.idPoint });
            this.setState({ cmt_point: data.cmtPoint });
            this.setState({ lib_point:  data.libPoint  });
            this.setState({ site: data.localite.idLoc });
            this.setState({ etat_point: data.etatPoint  });
            this.setState({ modif: true });
            this.setState({ selectedpoint: data });
            window.scrollTo(0, 0);
        }
    }

    annule_point() {
        this.setState({ id_point: 0 });
        this.setState({ cmt_point: '' });
        this.setState({ modif: false });
        this.setState({ showindicator: false });
        this.forceUpdate();
    }

    replace_virgule(val) {
        try {
            if (val.indexOf(',') > 0) {
                val = val.replace(',', '.');
            }
        }
        catch (e) {
            //alert('Exception '+e)
        }
        return val;
    }

    enreg_point() {
        if (this.state.cmt_point == "" || this.state.lib_point == null || this.state.lib_point == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                if (this.state.liste_loc == null || this.state.liste_loc == undefined) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Impossible de pointer car un site parametré' });
                    return;
                } else {
                    let trouve = false;
                    let rlatitude = '';
                    let rlongitude = '';
                    const location = window.navigator && window.navigator.geolocation
                    if (location) {
                        location.getCurrentPosition((position) => {
                            rlatitude = position.coords.latitude;
                            rlongitude = position.coords.longitude;
                            for (var i = this.state.liste_loc.length; i--;) {
                                let lat1 = this.replace_virgule(rlatitude);
                                let long1 = this.replace_virgule(rlongitude);
                                let lat2 = this.replace_virgule(this.state.liste_loc[i].latitudeLoc);
                                let long2 = this.replace_virgule(this.state.liste_loc[i].longitudeLoc);
                                try {
                                    let distance = geolib.getDistanceSimple(
                                        { latitude: lat1, longitude: long1 },
                                        { latitude: lat2, longitude: long2 }
                                    );
                                    if (distance <= 250) {
                                        let val = this.state.liste_loc[i];
                                        this.setState({ nom_loc: val.nomLoc + '/' + val.quartier.nomQuart, id_loc: val.idLoc });
                                        trouve = true; 
                                        this.myservice.enreg_point(0, this.state.cmt_point, this.state.lib_point, val.idLoc).then(data => this.resultat(data.code, data.contenu));
                                        break;
                                    }
                                }
                                catch (e) {
                                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Impossible de pointer ' + e });
                                    return;
                                }
                            }
                        }, (error) => {
                            this.toast.show({ severity: 'error', summary: 'Error', detail: 'Erreur de géolocalisation ' + error });
                            return;
                        })
                    }
                }
            } else {
                this.myservice.enreg_point(this.state.id_point, this.state.cmt_point, this.state.lib_point).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        if (rowData.etatPoint == 'Validé') {
            return <div className="p-grid" style={{ width: 90 }}>
                <div className="p-col"><Button disabled={true} onClick={(e) => this.edit_point(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
                <div className="p-col"><Button disabled={true} onClick={(e) => this.conf_update_etat_point(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
            </div>
        } else if (rowData.etatPoint == 'En attente') {
            return <div className="p-grid" style={{ width: 90 }}>
                <div className="p-col"><Button onClick={(e) => this.edit_point(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
                <div className="p-col"><Button onClick={(e) => this.conf_update_etat_point(rowData)} className="p-button-success" style={{ width: 25, height: 25 }} icon="pi pi-check" /></div>
            </div>
        } else {
            return <div className="p-grid" style={{ width: 90 }}>
                <div className="p-col"><Button onClick={(e) => this.edit_point(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
                <div className="p-col"><Button onClick={(e) => this.conf_update_etat_point(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
            </div>
        }



    }


    delete_point() {
        if (this.state.selectedpoint == null || this.state.selectedpoint == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.supr_point(this.state.selectedpoint.idPoint).then(data => this.resultat(data.code, data.contenu));
        }
    }

    update_etat_point() {
        if (this.state.selectedpoint == null || this.state.selectedpoint == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à modifier' });
        } else {
            let etat = this.state.selectedpoint.etatPoint;
            if (this.state.selectedpoint.etatPoint == 'En attente') {
                etat = 'Validé';
            } else if (this.state.selectedpoint.etatPoint == 'Annulé') {
                etat = 'Annulé';
            }
            this.myservice.update_etat_point(this.state.selectedpoint.idPoint, etat).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_update_etat_point(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedpoint: data });
            if (data.etatPoint == 'En attente') {
                this.setState({ msg: ' valider la ligne N° ' + data.ordre });
            } else if (data.etatPoint == 'Annulé') {
                this.setState({ msg: ' annuler la ligne N° ' + data.ordre });
            }
            this.setState({ visible_update_etat: true });
        }
    }

    conf_delete_point(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedpoint: data });
            this.setState({ msg: data.ordre });
            this.setState({ visible: true });
        }
    }


    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_point();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );


        const dialogFooter_etat = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_update_etat: false });
                    this.update_etat_point();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_update_etat: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        /*
        let lsite = null;
        if (this.state.liste_loc != null && this.state.liste_loc != undefined && this.state.liste_loc.length > 0) {
            lsite = this.state.liste_loc.map(x => {
                return { value: x.idLoc, label: x.nomLoc }
            });
        }
        */
        let luser = null;

        if (this.state.liste_user != null && this.state.liste_user != undefined && this.state.liste_user.length > 0) {
            let l1 = this.state.liste_user.map(x => {
                return { value: x.idUser, label: x.nomUser+' '+x.matrUser, telUser: x.telUser }
            });
            let l2 = [{ value: '0', label: 'Tous' }];
            luser = l2.concat(l1);

        }

        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible_update_etat} style={{ width: '250px' }} modal={true} footer={dialogFooter_etat} onHide={(e) => this.setState({ visible_update_etat: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer N° <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 1400 }}>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Libelle *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_lib_point} value={this.state.lib_point} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ lib_point: e.value })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label >Commentaire *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.cmt_point} onChange={(e) => this.setState({ cmt_point: e.target.value })} />
                                </div>
                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_point()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_point()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                <label style={{ color: '#EA540E', fontWeight: 'bold', fontSize: 20, marginTop: 5 }}>{this.state.nom_loc}</label>
                            </center>


                            <div className="p-col-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-3">
                                        <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} showTime={true} showSeconds={true} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} showTime={true} showSeconds={true} />
                                    </div>
                                    <div className="p-col-12 p-md-3">
                                        <Dropdown options={luser} value={this.state.rid_user} optionLabel='label' placeholder="Choisir une personne" filter={true} filterBy='label' filterMatchMode='contains'
                                            onChange={(e) => {
                                                this.setState({rid_user: e.value });
                                            }} autoWidth={false} itemTemplate={(option) => {
                                                if (!option.value) {
                                                    return option.label;
                                                }
                                                else {
                                                    return (
                                                        <div className="p-clearfix">
                                                            <div className="p-grid">
                                                                <div className="p-col-12 p-md-10">
                                                                    <label>{option.label}</label>
                                                                </div>
                                                                <div className="p-col-12 p-md-2">
                                                                    <label style={{fontWeight: 'bold'}}>{option.telUser}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }} panelStyle={{ width: '450px' }} />
                                    </div>
                                    <div className="p-col-12 p-md-2">
                                        <Button icon='pi pi-search' label='Rechercher' onClick={(e) => this.get_liste_point()} />
                                    </div>
                                    
                                </div>
                            </div>



                            <DataTable value={this.state.liste_point} paginatorPosition="top" selectionMode="single" paginator={true} rows={20}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedpoint: e.data })} onRowSelect={e => this.setState({ selectedpoint: e.data })}
                                responsive={true} selection={this.state.selectedpoint} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="dateArvPoint" header="Date" style={{ width: '8%' }} />
                                <Column field="idUser.nomUser" filter={true} filterMatchMode='contains' header="Personnel" sortable={true} style={{ width: '20%' }} />
                                <Column field="idUser.matrUser" header="Matricule" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="idUser.idSrv.nomSrv" header="Service" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                <Column field="dateArvPoint" filter={true} body={(rowData, column) => {
                                    let h = '';
                                    if (rowData.dateArvPoint !== undefined && rowData.dateArvPoint !== null) {
                                        h = rowData.dateArvPoint.split(" ")[1];
                                    }
                                    return <div>
                                        <span style={{ color: '#000' }}>{h}</span>
                                    </div>
                                }} filterMatchMode='contains' header="Arrivé" sortable={true} style={{ width: '8%' }} />
                                <Column field="dateDepPoint" filter={true} filterMatchMode='contains' body={(rowData, column) => {
                                    let h = '';
                                    if (rowData.dateDepPoint !== undefined && rowData.dateDepPoint !== null) {
                                        h = rowData.dateDepPoint.split(" ")[1];
                                    }
                                    return <div>
                                        <span style={{ color: '#000' }}>{h}</span>
                                    </div>
                                }} header="Départ" sortable={true} style={{ width: '8%' }} />
                                <Column field="cmtPoint" header="Commentaire" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '18%' }} />
                                <Column field="etatPoint" header="Etat" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '7%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}