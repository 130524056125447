import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

export class Form_periode_objectif extends Component {
    constructor() {
        super();
        this.state = {
            liste_pobj: [],
            selectedPobj: null,
            idPobj: 0,
            libPobj: '',
            debPobj: null,
            finPobj: null,
            finEvalPobj: null,
            debEvalPobj: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
        };
        this.edit_pobj = this.edit_pobj.bind(this);
        this.conf_delete_pobj = this.conf_delete_pobj.bind(this);
        this.myservice = new Digipointservice();
    }

    componentDidMount() {
        this.myservice.get_liste_pobj().then(data => this.setState({ liste_pobj: data }));
    }

    resultat(code, contenu) {
        if (code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_pobj();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_pobj(data) {
        if (data != null && data != undefined) {
            this.setState({ idPobj: data.idPobj });
            this.setState({ libPobj: data.libPobj });

            this.setState({ debEvalPobj:  moment(this.state.debEvalPobj, 'DD/MM/YYYY').format('DD/MM/YYYY') });
            this.setState({ finEvalPobj:  moment(this.state.finEvalPobj, 'DD/MM/YYYY').format('DD/MM/YYYY')});
            this.setState({ finPobj:  moment(this.state.finPobj, 'DD/MM/YYYY').format('DD/MM/YYYY') });
            this.setState({ debPobj:  moment(this.state.debPobj, 'DD/MM/YYYY').format('DD/MM/YYYY')});

            this.setState({ modif: true });
            this.setState({ selectedPobj: data });
            window.scrollTo(0, 0);
        }
    }

    annule_pobj() {
        this.setState({ debPobj: '' });
        this.setState({ libPobj: '' });
        this.setState({ debEvalPobj: '' });
        this.setState({ finPobj: '' });
        this.setState({ finEvalPobj: '' });

        this.setState({ idPobj: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_pobj() {
        if (this.state.libPobj == '' || this.state.debPobj == null || this.state.finPobj == null) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                this.setState({ showindicator: true });
                let data = { idPobj: '0', libPobj: this.state.libPobj, debPobj: moment(this.state.debPobj, 'DD/MM/YYYY').format('DD/MM/YYYY'), finPobj: moment(this.state.finPobj, 'DD/MM/YYYY').format('DD/MM/YYYY'), debEvalPobj: moment(this.state.debEvalPobj, 'DD/MM/YYYY').format('DD/MM/YYYY'), finEvalPobj: moment(this.state.finEvalPobj, 'DD/MM/YYYY').format('DD/MM/YYYY') };
                this.myservice.enreg_pobj(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                var data = this.state.selectedPobj;
                data.libPobj = this.state.libPobj;
                data.debPobj = moment(this.state.debPobj, 'DD/MM/YYYY').format('DD/MM/YYYY');
                data.finPobj = moment(this.state.finPobj, 'DD/MM/YYYY').format('DD/MM/YYYY');
                data.debEvalPobj = moment(this.state.debEvalPobj, 'DD/MM/YYYY').format('DD/MM/YYYY');
                data.finEvalPobj = moment(this.state.finEvalPobj, 'DD/MM/YYYY').format('DD/MM/YYYY');
                this.setState({ showindicator: true });
                this.myservice.enreg_pobj(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_pobj(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_pobj(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_pobj() {
        if (this.state.selectedPobj == null || this.state.selectedPobj == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.delete_pobj(this.state.selectedPobj).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_pobj(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedPobj: data });
            this.setState({ msg: data.libPobj });
            this.setState({ visible: true });
        }
    }


    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_pobj();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 600 }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="libCodif">Désignation *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.libPobj} onChange={(e) => this.setState({ libPobj: e.target.value, descGrp: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label >Début période *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.debPobj} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ debPobj: e.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label >Fin période *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.finPobj} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ finPobj: e.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label >Début évaluation *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.debEvalPobj} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ debEvalPobj: e.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label >Fin évaluation *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.finEvalPobj} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ finEvalPobj: e.value })} />
                                </div>

                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_pobj()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_pobj()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_pobj} paginatorPosition="top" selectionMode="single" paginator={true} rows={100}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedPobj: e.data })} onRowSelect={e => this.setState({ selectedPobj: e.data })}
                                responsive={true} selection={this.state.selectedPobj} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="libPobj" header="Désignation" sortable={true} style={{ width: '20%' }} />
                                <Column field="debPobj" header="Début période" sortable={true} />
                                <Column field="finPobj" header="Fin période" sortable={true} />
                                <Column field="debEvalPobj" header="Début évaluation" sortable={true} />
                                <Column field="finEvalPobj" header="Fin évaluation" sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}