import qs from 'querystring';

export default class Digipointservice {
    constructor() {
        this.state = { 
            //wsurl: 'http://localhost:8077/ws_digipoint/webresources/ws_digipoint',
            // wsurl: 'http://192.168.43.189:8095',
            //wsurl: 'http://192.168.101.15:8095',
            wsurl: 'https://new.xulanet.com/ws_digipoint',
            deviceUrl: 'http://192.168.98.33:8090',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
    }
 
    get_userconnected() {
        return this.state.userconnected;
    }

    addFace(data, photo) {
        var methode = 'POST';
        var lien = this.state.deviceUrl + '/face/create';
        console.log(photo);
        const formData = new FormData();
        formData.append('pass', '12345678');
        formData.append('personId', data.idUser);
        formData.append('faceId', 'F' + data.idUser);
        formData.append('imgBase64', photo);
        return fetch(lien, {
            method: methode,
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            console.log(data);
            return data;
        }).catch((error) => {
            return error
        })
    }

    addFaceByUrl(data, photo) {
        var methode = 'POST';
        var lien = this.state.deviceUrl + '/face/createByUrl';
        console.log(photo);
        const formData = new FormData();
        formData.append('pass', '12345678');
        formData.append('personId', data.idUser);
        formData.append('faceId', 'F' + data.idUser);
        formData.append('imgUrl', photo);
        return fetch(lien, {
            method: methode,
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            console.log(data);
            return data;
        }).catch((error) => {
            return error
        })
    }

    deleteFace(data) {
        var methode = 'POST';
        var lien = this.state.deviceUrl + '/face/delete';
        const formData = new FormData();
        formData.append('pass', '12345678');
        formData.append('faceId', 'F' + data.idUser);
        return fetch(lien, {
            method: methode,
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    addPerson(data) {
        var lien = this.state.deviceUrl + '/person/create';
        let new_data='pass=12345678&person='+encodeURIComponent(JSON.stringify({
            id: data.idUser,
            name: data.nomUser,
            idcardNum: ""
        }));
        return fetch(lien, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
           body: new_data
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            console.log(data)
            return data;
        }).catch((error) => {
            alert(error);
            return error
        })
    }

    updatePerson(data) {
        var lien = this.state.deviceUrl + '/person/update?pass=12345678';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    id: data.idUser,
                    name: data.nomUser,
                    idcardNum: ""
                }
            )
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    add_msg(data) {
        var lien = this.state.wsurl + '/filemsg/add_batch';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_file_by_msg(idMsg) {
        var lien = this.state.wsurl + '/filemsg/findByIdMsg?idMsg=' + idMsg;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    update_file_msg(data) {
        var lien = this.state.wsurl + '/filemsg/update';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_file_by_dest(refDest) {
        var lien = this.state.wsurl + '/filemsg/findByRefDest?refDest=' + refDest;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_all_msg() {
        var lien = this.state.wsurl + '/msg/get_all_msgs?id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_msg(spd1, spd2) {
        var lien = this.state.wsurl + '/msg/liste_msgs?pd1=' + spd1 + '&pd2=' + spd2 + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }





    download_fichier_joint(namefile, destination) {
        var lien = this.state.wsurl + '/utils/download_fichier_joint?namefile=' + namefile + '&destination=' + destination + '&id_user=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    download_file(namefile, destination) {
        var lien = this.state.wsurl + '/utils/download_file?namefile=' + namefile + '&destination=' + destination + '&id_user=' + this.state.userconnected.idUser;
        return lien
    }

  
    get_file_base64(namefile, destination) {
        var lien = this.state.wsurl + '/utils/download_base64?namefile=' + namefile + '&destination=' + destination + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
            console.log(data)
        }).catch((error) => {
            return error
        })
    }

    upload_file(file, namefile, destination) {
        var methode = 'POST';
        var lien = this.state.wsurl + '/utils/upload_file';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id_user', this.state.userconnected.idUser);
        formData.append('namefile', namefile);
        formData.append('destination', destination);
        return fetch(lien, {
            method: methode,
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    get_liste_lobj(idObj) {
        var lien = this.state.wsurl + '/ligneobj/findByIdObj?idObj=' + idObj;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    add_batch_lobj(data) {
        var lien = this.state.wsurl + '/ligneobj/add_batch';
        var methode = 'POST';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_lobj(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idLobj === '0') {
            lien = this.state.wsurl + '/ligneobj/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/ligneobj/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_lobj(data) {
        var lien = this.state.wsurl + '/ligneobj/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }





    get_liste_pobj() {
        var lien = this.state.wsurl + '/pobj/get_liste_pobj?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_pobj(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idPobj === '0') {
            lien = this.state.wsurl + '/pobj/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/pobj/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_pobj(data) {
        var lien = this.state.wsurl + '/pobj/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    get_liste_obj() {
        var lien = this.state.wsurl + '/obj/get_liste_obj?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_obj(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idObj === '0') {
            lien = this.state.wsurl + '/obj/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/obj/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_obj(data) {
        var lien = this.state.wsurl + '/obj/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }





    get_organigramme() {
        return fetch(this.state.wsurl + '/get_organigramme?id_user=' + this.state.userconnected.idUser)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {
                return error
            })
    }

    generer_liste_paye(spd1, spd2, ridBoutiq, rmatrPers, rstatutPaye, format) {
        var lien = this.state.wsurl + '/etats/generer_liste_paye?pd1=' + spd1 + '&pd2=' + spd2 + '&rmatrPers=' + rmatrPers + '&rstatutPaye=' + rstatutPaye + '&ridBoutiq=' + ridBoutiq + '&ridPaye=Tous' + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    generer_edition_bulletin(spd1, spd2, ridBoutiq, rmatrPers, rstatutPaye, ridPaye) {
        var lien = this.state.wsurl + '/etats/generer_bulletin_paye?pd1=' + spd1 + '&pd2=' + spd2 + '&rmatrPers=' + rmatrPers + '&rstatutPaye=' + rstatutPaye + '&ridBoutiq=' + ridBoutiq + '&ridPaye=' + ridPaye + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    get_liste_paye(spd1, spd2, ridBoutiq, rmatrPers, rstatutPaye) {
        var lien = this.state.wsurl + '/etats/liste_paye?pd1=' + spd1 + '&pd2=' + spd2 + '&rmatrPers=' + rmatrPers + '&rstatutPaye=' + rstatutPaye + '&ridBoutiq=' + ridBoutiq + '&ridPaye=Tous' + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_paye(mois, annee, id_boutiq) {
        var methode = 'POST';
        var lien = this.state.wsurl + '/paye/generer_paye?mois=' + mois + '&annee=' + annee + '&id_boutiq=' + id_boutiq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    update_paye(data) {
        var methode = 'PUT';
        var lien = this.state.wsurl + '/paye/update?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        })
            .then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    update_paye_batch(data) {
        var methode = 'PUT';
        var lien = this.state.wsurl + '/paye/update_batch?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_paye_batch(data) {
        var methode = 'DELETE';
        var lien = this.state.wsurl + '/paye/delete_batch?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        })
            .then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    delete_paye(data) {
        var methode = 'DELETE';
        var lien = this.state.wsurl + '/paye/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_bon(spd1, spd2, rmatr_pers, retat_bon) {
        var lien = this.state.wsurl + '/bon/liste_bon?pd1=' + spd1 + '&pd2=' + spd2 + '&rmatr_pers=' + rmatr_pers + '&retat_bon=' + retat_bon + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    enreg_bon(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idBon === '0') {
            lien = this.state.wsurl + '/bon/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/bon/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    delete_bon(data) {
        var lien = this.state.wsurl + '/bon/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }



    get_liste_accorder(rid_prime) {
        var lien = this.state.wsurl + '/accorder/liste_accorder?rid_prime=' + rid_prime + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    enreg_accorder(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idAccorder === '0') {
            lien = this.state.wsurl + '/accorder/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/accorder/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }
            ).catch((error) => {
                return error
            })
    }
    delete_accorder(data) {
        var lien = this.state.wsurl + '/accorder/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    get_liste_prime() {
        var lien = this.state.wsurl + '/primes/liste_prime?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_prime(data) {
        var methode = 'POST';
        var lien = '';
        if (data.idPrime === '0') {
            lien = this.state.wsurl + '/primes/add';
            data.idEtabl = this.state.userconnected.idEtabl.idEtabl;
        } else {
            methode = 'PUT';
            lien = this.state.wsurl + '/primes/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_prime(data) {
        var lien = this.state.wsurl + '/primes/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    get_dashboard(pdate, pid_dept) {
        return fetch(this.state.wsurl + '/etats/get_dashboard?pdate=' + pdate + '&pid_dept=' + pid_dept + '&id_user=' + this.state.userconnected.idUser)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    st_trache_retard(spd1, spd2, ptranche) {
        let lien = this.state.wsurl + '/etats/st_trache_retard?pd1=' + spd1 + '&pd2=' + spd2 + '&ptranche=' + ptranche + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    situtation_pointage_dept(spd1, spd2) {
        let lien = this.state.wsurl + '/etats/situtation_pointage_dept?pd1=' + spd1 + '&pd2=' + spd2 + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    situtation_pointage_div(spd1, spd2) {
        let lien = this.state.wsurl + '/etats/situtation_pointage_div?pd1=' + spd1 + '&pd2=' + spd2 + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    situtation_pointage_srv(spd1, spd2) {
        let lien = this.state.wsurl + '/etats/situtation_pointage_srv?pd1=' + spd1 + '&pd2=' + spd2 + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    situtation_pointage(spd1, spd2) {
        let lien = this.state.wsurl + '/etats/situtation_pointage?pd1=' + spd1 + '&pd2=' + spd2 + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    liste_edition_pointage(spd1, spd2, ruser) {
        let lien = this.state.wsurl + '/etats/liste_edition_pointage?pd1=' + spd1 + '&pd2=' + spd2 + '&ruser=' + ruser + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_identification(login, pwd) {
        var lien = this.state.wsurl + '/user/get_identification?loginUser=' + login + '&pwdUser=' + pwd;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            })
            .catch((error) => {
                return error
            })
    }

    //Gestion des absences
    get_process_abs(rid_abs) {
        let rid_user = this.state.userconnected.idUser;
        var lien = this.state.wsurl + '/get_process_abs?rid_abs=' + rid_abs + '&rid_user=' + rid_user + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                //_this.setState({ listecodif: data });
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    getListe_process_by_abs(rid_abs) {
        var lien = this.state.wsurl + '/get_liste_process_by_abs?rid_abs=' + rid_abs + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    getListe_process_abs(pd1, pd2, rid_user, rtype_abs, rid_abs) {
        var lien = this.state.wsurl + '/get_liste_abs?pd1=' + pd1 + '&pd2=' + pd2 + '&rid_user=' + rid_user + '&rtype_abs=' + rtype_abs + '&rid_abs=' + rid_abs + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    enreg_process_abs(id_proc, etat_proc, cmt_proc, id_user_resp, id_abs) {
        var lien = '';
        id_user_resp = this.state.userconnected.idUser;
        lien = this.state.wsurl + '/update_process_abs?id_proc=' + id_proc + '&etat_proc=' + etat_proc + '&cmt_proc=' + cmt_proc + '&id_user_resp=' + id_user_resp + '&id_abs=' + id_abs + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    supr_process_abs(data) {
        var lien = this.state.wsurl + '/proc_abs/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    //Gestion des absences
    getListe_abs(pd1, pd2, rid_user, rtype_abs) {
        var lien = this.state.wsurl + '/abs/get_liste_abs?pd1=' + pd1 + '&pd2=' + pd2 + '&rid_user=' + rid_user + '&rtype_abs=' + rtype_abs + '&id_user=' + this.state.userconnected.idUser;
        console.log(lien)
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    enreg_abs(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idAbs == "0") {
            lien = this.state.wsurl + '/abs/add';
            data.idEtabl = this.state.userconnected.idEtabl;
            data.iduser = this.state.userconnected.idUser;
        } else {
            lien = this.state.wsurl + '/abs/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_abs(data) {
        var lien = this.state.wsurl + '/abs/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    //Gestion des hierarchisations
    getListe_hie(rid_user) {
        var lien = this.state.wsurl + '/hie/get_liste_hie?rid_user=' + rid_user;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    enreg_hie(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idHie == "0") {
            lien = this.state.wsurl + '/hie/add';
            data.idEtabl = this.state.userconnected.idEtabl;
            data.iduser = this.state.userconnected.idUser;
        } else {
            lien = this.state.wsurl + '/hie/update';
            methode = 'PUT';
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_hie(data) {
        var lien = this.state.wsurl + '/hie/delete';
        let methode = 'DELETE';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    //Gestion des services
    getListe_srv() {
        var lien = this.state.wsurl + '/service/liste_srv?id_user=' + this.state.userconnected.idUser;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    getListe_srv_by_div(id_div) {
        var lien = this.state.wsurl + '/service/liste_srv_by_div?rid_div=' + id_div + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_srv(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idSrv == "0") {
            lien = this.state.wsurl + '/service/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.state.wsurl + '/service/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_srv(data) {
        var lien = this.state.wsurl + '/service/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    getListe_div() {
        var lien = this.state.wsurl + '/division/liste_div?id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_div(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idDiv == "0") {
            lien = this.state.wsurl + '/division/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.state.wsurl + '/division/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_div(data) {
        var lien = this.state.wsurl + '/division/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }



    getListe_dept_chef() {
        var lien = this.state.wsurl + '/dept/liste_dept?id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    getListe_dept() {
        var lien = this.state.wsurl + '/dept/get_liste_dept?id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_dept(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idDept == "0") {
            lien = this.state.wsurl + '/dept/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.state.wsurl + '/dept/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_dept(data) {
        var lien = this.state.wsurl + '/dept/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    enreg_user(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idUser == "0") {
            lien = this.state.wsurl + '/user/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.state.wsurl + '/user/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }

    getListe_user() {
        var lien = this.state.wsurl + '/user/get_liste_user?id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_user(data) {
        var lien = this.state.wsurl + '/user/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    getListe_groupe() {
        var lien = this.state.wsurl + '/grp/get_liste_grp?id_user=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_groupe(data) {
        var lien = '';
        let methode = 'POST';
        if (data.idGrp == 0) {
            lien = this.state.wsurl + '/grp/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.state.wsurl + '/grp/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_groupe(data) {
        var lien = this.state.wsurl + '/grp/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    getListe_horaire(rid_progh) {
        var lien = this.state.wsurl + '/horaire/get_liste_horaire?rid_progh=' + rid_progh;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_horaire(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idHor == "0") {
            lien = this.state.wsurl + '/horaire/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.state.wsurl + '/horaire/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_horaire(data) {
        var lien = this.state.wsurl + '/horaire/delete';
        let methode = 'DELETE';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    getListe_progh() {
        var lien = this.state.wsurl + '/progh/get_liste_progh?id_user=' + this.state.userconnected.idUser;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    enreg_progh(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idHor == "0") {
            lien = this.state.wsurl + '/progh/add';
            data.idEtabl = this.state.userconnected.idEtabl;
            data.iduser = this.state.userconnected.idUser;
        } else {
            lien = this.state.wsurl + '/progh/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_progh(data) {
        var lien = this.state.wsurl + '/progh/delete';
        let methode = 'DELETE';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    getListe_etabl() {
        var lien = this.state.wsurl + '/etablissement/liste_etablissement';
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    enreg_etabl(data) {
        var lien = '';
        let methode = 'POST';
        if (data.idEtabl == '0') {
            lien = this.state.wsurl + '/etablissement/add';
        } else {
            lien = this.state.wsurl + '/etablissement/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_etabl(data) {
        let lien = this.state.wsurl + '/etablissement/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }





    enreg_point(id_point, cmt_point, lib_point, id_loc) {
        let lien = '';
        if (id_point == 0) {
            lien = this.state.wsurl + '/add_pointage?lib_point=' + lib_point + '&cmt_point=' + cmt_point + '&id_loc=' + id_loc + '&id_user=' + this.state.userconnected.idUser
        } else {
            lien = this.state.wsurl + '/update_cmt_pointage?id_point=' + id_point + '&cmt_point=' + cmt_point + '&lib_point=' + lib_point + '&id_user=' + this.state.userconnected.idUser
        }
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    cloturer_point(id_point) {
        return fetch(this.state.wsurl + '/cloturer_pointage?id_point=' + id_point + '&id_user=' + this.state.userconnected.idUser)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })

    }

    update_etat_point(id_point, etat_point) {
        return fetch(this.state.wsurl + '/update_etat_pointage?id_point=' + id_point + '&etat_point=' + etat_point + '&id_user=' + this.state.userconnected.idUser)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })

    }


    enreg_localite(data) {
        let lien = '';
        let methode = 'POST';
        if (data.idLoc == "0") {
            lien = this.state.wsurl + '/localite/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.state.wsurl + '/localite/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_localite() {
        var lien = this.state.wsurl + '/localite/get_liste_loc?id_user=' + this.state.userconnected.idUser;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    supr_localite(data) {
        var lien = this.state.wsurl + '/localite/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    get_liste_point(spd1, spd2, ruser) {
        let lien = this.state.wsurl + '/pointage/lite_pointage?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_user=' + ruser + '&id_user=' + this.state.userconnected.idUser;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                return error
            })
    }

    get_liste_crd() {
        return fetch(this.state.wsurl + '/crd/liste_crd')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    get_liste_quartier() {
        return fetch(this.state.wsurl + '/quartier/liste_quartier')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    enreg_quartier(data) {
        let lien = '';
        let methode = 'POST';
        if (data.idQuart == "0") {
            lien = this.state.wsurl + '/quartier/add';
        } else {
            lien = this.state.wsurl + '/quartier/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;

        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    supr_quartier(data) {
        let lien = this.state.wsurl + '/quartier/delete';
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    getListe_codif(_this, type_codif) {
        var lien = this.state.wsurl + '/get_liste_codif?type_codif=' + type_codif + '&id_boutiq=' + this.state.userconnected.boutique.ID_BOUTIQ + '&id_user=' + this.state.userconnected.idUser + '&login_user=' + this.state.userconnected.loginUser + '&profil_user=' + this.state.userconnected.profilUser + '&pwd_user=' + this.state.userconnected.pwdUser + '&id_etabl=' + this.state.userconnected.etabl.idetabl;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                //alert(data);
                //_this.setState({ listecodif: data });
                return data;
            }

            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    enreg_codif(id_codif, type_codif, lib_codif, desc_codif) {
        var lien = this.state.wsurl + '/enreg_codif?id_codif=' + id_codif + '&type_codif=' + type_codif + '&lib_codif=' + lib_codif + '&desc_codif=' + desc_codif + '&id_boutiq=' + this.state.userconnected.boutique.ID_BOUTIQ + '&id_user=' + this.state.userconnected.idUser + '&login_user=' + this.state.userconnected.loginUser + '&profil_user=' + this.state.userconnected.profilUser + '&pwd_user=' + this.state.userconnected.pwdUser + '&id_etabl=' + this.state.userconnected.etabl.idetabl;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }

    supr_codif(id_codif) {
        var lien = this.state.wsurl + '/delete_codif?id_codif=' + id_codif + '&id_boutiq=' + this.state.userconnected.boutique.ID_BOUTIQ + '&id_user=' + this.state.userconnected.idUser + '&login_user=' + this.state.userconnected.loginUser + '&profil_user=' + this.state.userconnected.profilUser + '&pwd_user=' + this.state.userconnected.pwdUser + '&id_etabl=' + this.state.userconnected.etabl.idetabl;
        return fetch(lien)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    //alert('nok')
                }
            })
            .then(data => {
                return data;
            }
            )
            .catch((error) => {
                // alert(error)
                return error
            })
    }



}