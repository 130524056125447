import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { OrganizationChart } from 'primereact/organizationchart';
import { ScrollPanel } from 'primereact/scrollpanel';

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chart } from 'primereact/chart';

export class Form_organigramme extends Component {
    constructor() {
        super();
        this.state = {
            data_org: [],
            // fic_url: 'http://10.173.36.47:8888/fic_digipoint',
            fic_url: 'http://192.168.43.252:8888/fic_digipoint',

        };
        this.nodeTemplate = this.nodeTemplate.bind(this);
        this.myservice = new Digipointservice();
    }

    componentDidMount() {
        this.myservice.get_organigramme().then(data => this.setState({ data_org: data, showindicator: false }));
    }

    nodeTemplate(node) {
        if (node.type === "person") {
            let src=this.state.fic_url + '/photo/'+node.data.avatar;
            return (
                <div>
                    <div className="node-header">{node.label}</div>
                    <div className="node-content">
                        <img alt={node.data.avatar} src={src} style={{ width: '45px', height: '45px' }} />
                        <div>{node.data.name}</div>
                    </div>
                </div>
            );
        }

        if (node.type === "department") {
            return node.label;
        }
    }

    render() {
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 800 }}>
                            <ScrollPanel style={{ width: '100%', height: '700px' }}>
                                {this.state.data_org.length > 0 ? <OrganizationChart value={this.state.data_org} nodeTemplate={this.nodeTemplate} className="company" /> : null}
                            </ScrollPanel>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}