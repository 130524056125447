import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import  Digipointservice  from '../service/Digipointservice';
import { ProgressSpinner } from 'primereact/progressspinner';
 
export default class Login extends Component {
	constructor() {
		super();
		this.state = {
			login: '',
			pwd: '',
			msg: '',
			showindicator: false,

		};
		this.identification = this.identification.bind(this);
		this.myservice = new Digipointservice();
	}

	handleKeyPress = e => {
		if (e.keyCode === 13) {
			this.identification();
		}
	}

	identification() {
		if (this.state.login == '' || this.state.pwd == '') {
			this.setState({ msg: 'Veuilllez saisir le login et le mot de passe' });
		} else {
			this.setState({ showindicator: true });
			this.myservice.get_identification(this.state.login, this.state.pwd).then(data => {
				if (data !== null && data !== undefined) {
					if (data.actifUser === 'Oui') {
						localStorage.setItem('userconnected', JSON.stringify(data));
						if (data.idGrp.nomGrp == 'AGENT') {
							window.location = "#/accueil";
						} else if (data.idGrp.nomGrp == 'DIRECTEUR' || data.idGrp.nomGrp == 'CHEF DIVISION' || data.idGrp.nomGrp == 'CHEF SERVICE' || data.idGrp.nomGrp == 'ADMINISTRATEUR') {
							window.location = "#/dashboard";
						}
						this.setState({ showindicator: false });

					} else if (data.actifUser === 'Expiré') {
						this.setState({ showindicator: false });
						this.setState({ msg: "Votre compte a expiré, merci de contacter l'administrateur aux 625 50 50 21 / 622 81 61 25" });
					} else {
						this.setState({ showindicator: false });
						this.setState({ msg: "Vous n'etes pas autorisés à vous connecter, merci de contacter l'administrateur aux 625 50 50 21 / 622 81 61 25" });
					}
				} else {
					this.setState({ showindicator: false });
					this.setState({ msg: 'Login ou mot de passe incorrects' });
				}
			});
		}
	}


	render() {
		return <div className="login-body">
			<div className="body-container">
				<div className="p-grid">
					<div className="p-col-12 p-lg-6 left-side">
						<img src="assets/layout/images/logo-dark.png" style={{width: '300px', height: '300px'}}  alt="apollo-layout" className="logo" />
						<h1 style={{color: '#ee7a11'}}>Bienvenue</h1>
						<p style={{color: '#fff'}}>
							Votre fiche d'émargement au bout des doigts
						</p>
					</div>
					<div className="p-col-12 p-lg-6 right-side">
						<div className="login-wrapper">
							<div className="login-container">
								<span className="title">Identification</span>

								<div className="p-grid p-fluid">
									<div className="p-col-12">
										<InputText value={this.state.login} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ login: e.target.value })} placeholder="Login" />
									</div>
									<div className="p-col-12">
										<InputText value={this.state.pwd} onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ pwd: e.target.value })} type="password" placeholder="Mot de passe" />
									</div>
									<div className="p-col-6">
									<Button label="Connexion" icon="pi pi-sign-in" onClick={() => { this.identification() }} />
										{this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px',marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
										<label style={{color: 'red', marginTop: 10}} >{this.state.msg}</label>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	}
}