import React, { Component } from 'react';
import Digipointservice from '../service/Digipointservice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';


export class Form_MsgInbox extends Component {
    constructor() {
        super();
        this.state = {
            liste_file_msg: [],
            selectedMsg: null,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            contenuMsg: '',
            canalMsg: '',
            objMsg: '',
            showindicator: false,
        };
        this.myservice = new Digipointservice();
    }

    componentDidMount() {
        this.rech_liste_msg();

    }

    rech_liste_msg() {
        this.setState({ showindicator: true });
        this.myservice.get_liste_file_by_dest(this.myservice.get_userconnected().idUser).then(data => this.setState({ liste_file_msg: data, showindicator: false }));
    }



    update_lecture_msg(data) {
        this.myservice.update_file_msg(data).then(data => {
            this.rech_liste_msg();
        });
    }

    render() {

        return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12 p-lg-5">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 600 }}>
                                    <DataTable value={this.state.liste_file_msg} responsive={true} selectionMode="single"
                                        scrollable scrollHeight="400px" selection={this.state.selectedMsg}
                                        onRowClick={e => this.setState({ selectedMsg: e.data })} onRowSelect={e => this.setState({ selectedMsg: e.data }, () => {
                                            this.update_lecture_msg(this.state.selectedMsg);
                                            this.setState({
                                                objMsg: this.state.selectedMsg.idMsg.objMsg,
                                                canalMsg: this.state.selectedMsg.idMsg.canalMsg,
                                                contenuMsg: this.state.selectedMsg.msgFil,
                                            })
                                        })} >
                                        <Column field="idMsg.dateMsg" filter={true} filterMatchMode='contains' header="Date" sortable={true} style={{ width: '20%' }} />
                                        <Column field="idMsg.objMsg" filter={true} filterMatchMode='contains' header="Objet" sortable={true} style={{ width: '60%' }} />
                                        <Column field="idMsg.canalMsg" filter={true} filterMatchMode='contains' header="Canal" sortable={true} style={{ width: '20%' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-7">
                        <div className="card card-w-title" style={{ height: 600 }}>
                        <center>
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </center>
                            <div>
                                <div className="p-grid">
                                    <div className="p-col-6 p-md-6">
                                        <label htmlFor="descCodif">Canal </label>
                                    </div>
                                    <div className="p-col-6 p-md-6">
                                        <label style={{fontSize: 18,color: '#000'}} htmlFor="descCodif">{this.state.canalMsg}</label>
                                    </div>

                                    <div className="p-col-6 p-md-6">
                                        <label htmlFor="descCodif">Objet </label>
                                    </div>
                                    <div className="p-col-6 p-md-6">
                                        <label style={{fontSize: 18,color: '#000'}} htmlFor="descCodif">{this.state.objMsg}</label>                                    
                                        </div>

                                    <div className="p-col-12 p-md-2">
                                        <label htmlFor="descCodif">Message </label>
                                    </div>
                                    <div className="p-col-12 p-md-10">
                                        <hr/>
                                        <label style={{fontSize: 18,color: '#000'}} htmlFor="descCodif">{this.state.contenuMsg}</label>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>




                </div>
            </div>
        );
    }
}