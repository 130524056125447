import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';

export class Form_depart extends Component {
    constructor() {
        super();
        this.state = {
            liste_dept: [],
            selecteddept: null,
            nomDept: '',
            codeDept: '',
            msg: '',
            modif: false,
            visible: false,
            liste_user: [],
            user: null,
            showindicator: false,
        };
        this.edit_dept = this.edit_dept.bind(this);
        this.conf_delete_dept = this.conf_delete_dept.bind(this);
        this.myservice = new Digipointservice();
    }

    componentDidMount() {
        this.rechliste_dept();
        this.myservice.getListe_user().then(data => this.setState({ liste_user: data }));
    }

    rechliste_dept() {
        this.myservice.getListe_dept_chef().then(data => this.setState({ liste_dept: data }));
    }

    resultat(code, contenu) {
        if (code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_dept();
            this.rechliste_dept();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_dept(data) {
        if (data != null && data != undefined) {
            this.setState({ nomDept: data.nom_depart });
            this.setState({ codeDept: data.code_depart });
            this.setState({ modif: true });
            this.setState({ selecteddept: data });
            this.setState({user: data.directeur.idUser})
            window.scrollTo(0, 0)
        }
    }

    annule_dept() {
        this.setState({ codeDept: '' });
        this.setState({ nomDept: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_dept() {
        if (this.state.nomDept == "" || this.state.codeDept == '' || this.state.user==null || this.state.user==undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                let data={idDept: '0',nomDept: this.state.nomDept,codeDept: this.state.codeDept,idUserResp: this.state.user};
                this.myservice.enreg_dept(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                var data=this.state.selecteddept;
                data.idDept=this.state.selecteddept.id_depart;
                data.nomDept=this.state.nomDept;
                data.codeDept=this.state.codeDept;
                data.idUserResp=this.state.user;
                this.myservice.enreg_dept(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_dept(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_dept(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_dept() {
        if (this.state.selecteddept == null || this.state.selecteddept == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.myservice.supr_dept(this.state.selecteddept.id_depart).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_dept(data) {
        if (data != null && data != undefined) {
            this.setState({ selecteddept: data });
            this.setState({ msg: data.nom_depart });
            this.setState({ visible: true });
        }
    }
 

    render() {
        let luser = null;
        if (this.state.liste_user != null && this.state.liste_user != undefined && this.state.liste_user.length > 0) {
            let l1 = this.state.liste_user.map(x => {
                return { value: x.idUser, label: x.nomUser + ' ' + x.matrUser }
            });
            let l2 = [{ value: '-1', label: 'AUCUN RESPONSABLE POUR LE MOMENT' }];
            luser = l2.concat(l1);
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_dept();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 600 }}>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-1">
                                    <label htmlFor="libCodif">Nom  *</label>
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <InputText value={this.state.nomDept} onChange={(e) => this.setState({ nomDept: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-1">
                                    <label >Code *</label>
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <InputText value={this.state.codeDept} onChange={(e) => this.setState({ codeDept: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-1">
                                    <label >Directeur </label>
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <Dropdown options={luser} placeholder='Choisir une personne' filter={true} filterMatchMode='contains' filterBy='label' value={this.state.user} optionLabel='label' onChange={event => this.setState({ user: event.value})} autoWidth={false} />
                                </div>
                            </div>
                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_dept()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_dept()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_dept} paginatorPosition="top" selectionMode="single" paginator={true} rows={100}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selecteddept: e.data })} onRowSelect={e => this.setState({ selecteddept: e.data })}
                                responsive={true} selection={this.state.selecteddept} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '10%' }} />
                                <Column field="ordre" header="N°" sortable={true} style={{ width: '5%' }} />
                                <Column field="code_depart" header="Code departement" sortable={true} style={{ width: '25%' }} />
                                <Column field="nom_depart" header="Nom departement" sortable={true} style={{ width: '30%' }} />
                                <Column field="directeur.nomUser" header="Directeur" sortable={true} style={{ width: '30%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}