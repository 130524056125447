import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import './styles.css';
import {ProgressSpinner} from 'primereact/progressspinner';

export class Form_localite extends Component {
    constructor() {
        super();
        this.state = {
            nomLoc: '',
            indicLoc: '',
            typeLoc: null,
            longitudeLoc: '',
            latitudeLoc: '',
            idQuart: null,
            idLoc: 0,
            liste_loc: [],
            liste_quart: [],
            liste_typeLoc: [
                { label: 'Bar restaurant', value: 'Bar restaurant' },
                { label: 'Gare', value: 'Gare' },
                { label: 'Aéroport', value: 'Aéroport' },
                { label: 'Ambassade', value: 'Ambassade' },
                { label: 'Boucherie', value: 'Boucherie' },
                { label: 'Boulangerie', value: 'Boulangerie' },
                { label: 'Banque', value: 'Banque' },
                { label: 'Boutique', value: 'Boutique' },
                { label: 'Bureau', value: 'Bureau' },
                { label: 'Clinic', value: 'Clinic' },
                { label: 'Centre commercial', value: 'Centre commercial' },
                { label: 'Commissariat', value: 'Commissariat' },
                { label: 'Eglise', value: 'Eglise' },
                { label: 'Ecole', value: 'Ecole' },
                { label: 'Gendarmerie', value: 'Gendarmerie' },
                { label: 'Gouvernorat', value: 'Gouvernorat' },
                { label: 'Habitation', value: 'Habitation' },
                { label: 'Hopital', value: 'Hopital' },
                { label: 'Hotel', value: 'Hotel' },
                { label: 'Laboratoire', value: 'Laboratoire' },
                { label: 'Mosquée', value: 'Mosquée' },
                { label: 'Marché', value: 'Marché' },
                { label: 'Motel', value: 'Motel' },
                { label: 'Pharmacie', value: 'Pharmacie' },
                { label: 'Préfectorat', value: 'Préfectorat' },
                { label: 'Quincallerie', value: 'Quincallerie' },
                { label: 'Rond point', value: 'Rond point' }
            ],
            selectedloc: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false
        };
        this.edit_loc = this.edit_loc.bind(this);
        this.conf_delete_loc = this.conf_delete_loc.bind(this);
        this.myservice = new Digipointservice();
    }

    componentDidMount() {
        this.get_liste_loc();
        this.myservice.get_liste_quartier('','','','').then(data => this.setState({ liste_quart: data }));
       // this.get_location();
    }

    get_location(){
        this.setState({showindicator: true});
        const location = window.navigator && window.navigator.geolocation
        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({latitudeLoc: position.coords.latitude,longitudeLoc: position.coords.longitude});
                this.setState({showindicator: false});
                //alert(position.coords.latitude+' , '+position.coords.longitude)
            }, (error) => {
                this.setState({ latitudeLoc: '0', longitudeLoc: '0' })
            })
        }
    }

    get_liste_loc() {
        this.myservice.get_liste_localite().then(data => this.setState({ liste_loc: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_loc();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_loc(data) {
        if (data != null && data != undefined) {
            this.setState({ idLoc: data.idLoc });
            this.setState({ nomLoc: data.nomLoc });
            this.setState({ longitudeLoc: data.longitudeLoc });
            this.setState({ latitudeLoc: data.latitudeLoc });
            this.setState({ indicLoc: data.indicLoc });
            this.setState({ typeLoc:  data.typeLoc  });
            this.setState({ idQuart:  data.idQuart.idQuart});
            this.setState({ modif: true });
            this.setState({ selectedloc: data });
            window.scrollTo(0, 0);
        }
    }

    annule_loc() {
        this.setState({ idLoc: 0 });
        this.setState({ nomLoc: '' });
        this.setState({ indicLoc: '' });
        this.setState({ longitudeLoc: '' });
        this.setState({ latitudeLoc: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_loc() {
        if (this.state.nomLoc == "" ||this.state.indicLoc == "" ||this.state.longitudeLoc == ""||this.state.latitudeLoc == "" ||  this.state.idQuart == null || this.state.idQuart == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            if (this.state.modif == false) {
                let data={idLoc: 0,nomLoc: this.state.nomLoc,longitudeLoc: this.state.longitudeLoc,latitudeLoc: this.state.latitudeLoc,typeLoc: this.state.typeLoc,indicLoc: this.state.indicLoc,idQuart: {idQuart: this.state.idQuart}};
                this.myservice.enreg_localite(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data=this.state.selectedloc;
                data.longitudeLoc=this.state.longitudeLoc;
                data.latitudeLoc=this.state.latitudeLoc;
                data.indicLoc=this.state.indicLoc;
                data.typeLoc=this.state.typeLoc;
                data.idQuart={idQuart: this.state.idQuart};
                this.myservice.enreg_localite(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_loc(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_loc(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_loc() {
        if (this.state.selectedloc == null || this.state.selectedloc == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne ‡ supprimer' });
        } else {
            this.myservice.supr_loc(this.state.selectedloc.idloc).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_loc(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedloc: data });
            this.setState({ msg: data.nomLoc });
            this.setState({ visible: true });
        }
    }


    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_loc();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        let lquart = null;
        if(this.state.liste_quart!=null && this.state.liste_quart!=undefined && this.state.liste_quart.length>0){
            lquart = this.state.liste_quart.map(x => {
                return { value: x.idQuart, label: x.nomQuart+' - '+x.idCrd.nomCrd }
            });
        }
       
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 800 }}>
                           
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label >Nom site *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText  value={this.state.nomLoc} onChange={(e) => this.setState({ nomLoc: e.target.value })} />
                                </div>
                             
                                <div className="p-col-12 p-md-2">
                                    <label >Indication *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText  value={this.state.indicLoc} onChange={(e) => this.setState({ indicLoc: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label >Longitude *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText  value={this.state.longitudeLoc} onChange={(e) => this.setState({ longitudeLoc: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label >Latitude *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText  value={this.state.latitudeLoc} onChange={(e) => this.setState({ latitudeLoc: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Quartier *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={lquart} value={this.state.idQuart} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ idQuart: e.value })} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Type *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_typeLoc} value={this.state.typeLoc} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ typeLoc: e.value })} autoWidth={false} />
                                </div>

                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_loc()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_loc()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{width: '30px', height: '30px'}} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s"/> : null}
                            </center>

                            <DataTable value={this.state.liste_loc} paginatorPosition="top" selectionMode="single" paginator={true} rows={10}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedloc: e.data })} onRowSelect={e => this.setState({ selectedloc: e.data })}
                                responsive={true} selection={this.state.selectedloc} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="nomLoc" filter={true} filterMatchMode='contains' header="Nom site" sortable={true} style={{ width: '20%' }} />
                                <Column field="longitudeLoc" filter={true} filterMatchMode='contains' header="Longitude" sortable={true} style={{ width: '10%' }} />
                                <Column field="latitudeLoc" header="Latitude" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="indicLoc" header="Indication" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '20%' }} />
                                <Column field="typeLoc" header="Type site" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                <Column field="idQuart.nomQuart" filter={true} filterMatchMode='contains' header="Quartier" sortable={true} style={{ width: '23%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}