import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';

export class FormChangepwd extends Component {
    constructor() {
        super();
        this.state = {
            newPWD: '',
            anPWD: '',
            confnewPWD: '',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            showindicator: false
        };
        this.myservice = new Digipointservice();
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: 'Mot de passe changé avec succès' });
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }
  

    update_user() {
        if (this.state.anPWD == '' || this.state.anPWD == undefined || this.state.newPWD == '' || this.state.newPWD == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        }else if (this.state.anPWD !=  this.state.userconnected.pwdUser ) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: "L'ancien mot de passe est incorrect" });
        }else if (this.state.newPWD !=  this.state.confnewPWD ) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: "Veuillez bien confirmer le nouveau mot de passe" });
        }else if (this.state.anPWD ==  this.state.newPWD ) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: "L'ancien et le nouveau mot de passe ne peuvent etre identiques" });
        } else {
            let data = this.state.userconnected;
            data.mp=this.state.newPWD;
            this.setState({showindicator: true});
            this.myservice.enreg_user(data).then(data => {
                this.resultat(data.code, data.contenu);
                this.setState({showindicator: false});
            });
        }
    }

    annule_user() {
       this.setState({anPWD: ''});
       this.setState({newPWD: ''});
       this.setState({confnewPWD: ''});
       this.forceUpdate();
    }

   

    render() {
       
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 800 }}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-3">
                                    <label >Ancien mot de passe *</label>
                                </div>
                                <div className="p-col-12 p-md-9">
                                    <InputText value={this.state.anPWD} type="password"  onChange={(e) => this.setState({ anPWD: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-3">
                                    <label >Nouveau mot de passe *</label>
                                </div>
                                <div className="p-col-12 p-md-9">
                                    <InputText value={this.state.newPWD} type="password"  onChange={(e) => this.setState({ newPWD: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-3">
                                    <label >Confirmer nouveau mot de passe *</label>
                                </div>
                                <div className="p-col-12 p-md-9">
                                    <InputText value={this.state.confnewPWD} type="password"  onChange={(e) => this.setState({ confnewPWD: e.target.value })} />
                                </div>

                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_user()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.update_user()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}