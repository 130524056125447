import React, { Component } from 'react';
import Digipointservice from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import './styles.css';
import { Calendar } from 'primereact/calendar';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';

export class Form_users extends Component {
    constructor() {
        super();
        this.state = {
            rlogin_user: '',
            rnom_user: '',
            rsite: '',
            rprofil_user: '',
            ractif_user: '',
            actifs: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' }
            ],
            items: [
                {
                    label: 'Modifier',
                    icon: 'pi pi-pencil',
                    command: (e) => {
                        this.edit_user();
                    }
                },
                {
                    label: 'Supprimer',
                    icon: 'pi pi-times',
                    command: (e) => {
                        this.conf_delete_user();
                    }
                },
                {
                    label: 'Hiérarchie',
                    icon: 'pi pi-sitemap',
                    command: (e) => {
                        this.aff_dlg_hie();
                    }
                },
                {
                    label: 'Synchroniser',
                    icon: 'pi pi-sync',
                    command: (e) => {
                        this.conf_sync();
                    }
                }
            ],
            idUser: '',
            loginUser: '',
            nomUser: '',
            pwdUser: '',
            telUser: '',
            emailUser: '',
            idGrp: null,
            profilUser: null,
            actifUser: null,
            hierarchieUser: null,
            niveauEtudUser: null,
            lieuNaisslUser: '',
            motifDepUser: null,
            matrUser: '',
            photoUser: 'nophoto.png',
            liste_users: [],
            liste_site: [],
            liste_groupe: [],
            liste_srv: [],
            liste_progh: [],
            selectedUser: null,
            groupe: null,
            idLoc: null,
            idSrv: null,
            idProgHor: null,
            msg: '',
            modif: false,
            visible: false,
            file: null,
            liste_hie: [],
            selectedhie: null,
            idHie: 0,
            user_resp: null,
            modif_hie: false,
            debHie: '',
            finHie: '',
            actifHie: null,
            numHie: null,
            liste_numHie: [
                { label: 'N+1', value: 1 },
                { label: 'N+2', value: 2 },
                { label: 'N+3', value: 3 }
            ],
            visible_hie: false,
            showindicator: false,
            dateNaissUser: '',
            smaritalUser: null,
            sexeUser: null,
            lieuNaisslUser: '',
            dateEmbUser: '',
            dateDepUser: '',

            liste_sexeUser: [
                { label: 'Masculin', value: 'Masculin' },
                { label: 'Feminin', value: 'Feminin' }
            ],
            liste_smarital_user: [
                { label: 'Célibataire', value: 'Célibataire' },
                { label: 'Marié', value: 'Marié' },
                { label: 'Veuve', value: 'Veuve' }
            ],
            liste_hierarchie_user: [
                { label: 'A', value: 'A' },
                { label: 'B', value: 'B' },
                { label: 'C', value: 'C' }
            ],
            liste_motif_dep: [
                { label: 'NA', value: '' },
                { label: 'Retraite', value: 'Retraite' },
                { label: 'Licenciement', value: 'Licenciement' },
                { label: 'Démission', value: 'Démission' },
                { label: 'Décès', value: 'Décès' },
                { label: 'Inaptitude', value: 'Inaptitude' }
            ],
            liste_niveau_etude: [
                { label: 'Universitaire', value: 'Universitaire' },
                { label: 'Primaire', value: 'Primaire' },
                { label: 'Collège', value: 'Collège' },
                { label: 'Lycée', value: 'Lycée' },
                { label: 'Professionnel', value: 'Professionnel' }
            ]
        };
        this.edit_user = this.edit_user.bind(this);
        this.conf_delete_user = this.conf_delete_user.bind(this);

        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)

        this.myservice = new Digipointservice();

        this.edit_hie = this.edit_hie.bind(this);
        this.aff_dlg_hie = this.aff_dlg_hie.bind(this);
        this.conf_sync = this.conf_sync.bind(this);

    }

    conf_sync() {
        if (this.state.selectedUser == null || this.state.selectedUser == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sÈlectionner la ligne ‡ supprimer' });
        } else {
            let namefile = this.state.selectedUser.photoUser;
            /*
             this.myservice.addPerson(this.state.selectedUser).then(data => {
                if (data !== null && data !== undefined) {
                    if (data.code == '000') {
                        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Mise à jour ID avec succès' });
                        this.myservice.get_file_base64(namefile, 'photos').then(imgBase64 => {
                            this.myservice.addFace(this.state.selectedUser, imgBase64).then(repFace => {
                                if (repFace !== null && repFace !== undefined) {
                                    if (repFace.code == '000') {
                                        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Mise à jour face effectuée avec succès' });
                                    } else {
                                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec mise à jour' });
                                    }
                                }
                            });
                        });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec mise à jour' });
                    }
                }
            });
           */
            this.myservice.addPerson(this.state.selectedUser).then(data => {
                if (data !== null && data !== undefined) {
                    if (data.code == '000') {
                        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Mise à jour ID avec succès' });
                        let imgBase64 = this.myservice.download_file(namefile, 'photos');
                        this.myservice.addFaceByUrl(this.state.selectedUser, imgBase64).then(repFace => {
                            if (repFace !== null && repFace !== undefined) {
                                if (repFace.code == '000') {
                                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Mise à jour face effectuée avec succès' });
                                } else {
                                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec mise à jour' });
                                }
                            }
                        });
                    } else {
                        this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec mise à jour' });
                    }
                }
            });

        }
    }

    new_sync(person) {
        this.myservice.addPerson(person).then(data => {
            if (data !== null && data !== undefined) {
                if (data.code == '000') {
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'Mise à jour ID avec succès' });
                    let imgBase64 = this.myservice.get_file_base64(person.photoUser, 'photos');
                    this.myservice.addFace(person, imgBase64).then(repFace => {
                        if (repFace !== null && repFace !== undefined) {
                            if (repFace.code == '000') {
                                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Mise à jour face effectuée avec succès' });
                            } else {
                                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec mise à jour' });
                            }
                        }
                    });
                } else {
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec mise à jour' });
                }
            }
        });
    }

    fileUpload(file, namefile) {
        this.myservice.upload_file(file, namefile, 'photos').then(data => {
            if (data.code == '200') {
                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Logo chargé avec succès' });
            } else {
                this.toast.show({ severity: 'error', summary: 'Error', detail: 'Echec upload' });
            }
        });
    }

    onChange(e) {
        this.setState({ file: e.target.files[0] })
    }

    componentDidMount() {
        this.get_liste_users();
        this.myservice.getListe_groupe().then(data => this.setState({ liste_groupe: data }));
        this.myservice.getListe_srv().then(data => this.setState({ liste_srv: data }));
        this.myservice.get_liste_localite().then(data => this.setState({ liste_site: data }));
        this.myservice.getListe_progh().then(data => this.setState({ liste_progh: data }));
    }

    get_liste_users() {
        this.setState({ showindicator: true });
        this.myservice.getListe_user().then(data => this.setState({ liste_users: data, showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_user();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    get_liste_hie() {
        this.myservice.getListe_hie(this.state.selectedUser.idUser).then(data => this.setState({ liste_hie: data }));
    }

    resultat_hie(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_hie();
            this.get_liste_hie();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_hie(data) {
        if (data != null && data != undefined) {
            this.setState({ idHie: data.idHie });
            this.setState({ debHie: data.debHie });
            this.setState({ finHie: data.finHie });
            this.setState({ actifHie: data.actifHie });
            this.setState({ user_resp: data.idResp.idUser });
            this.setState({ numHie: data.numHie });
            this.setState({ modif_hie: true });
            this.setState({ selectedhie: data });
        }
    }

    edit_user(data) {
        if (data != null && data != undefined) {
            this.setState({ idUser: data.idUser });
            this.setState({ loginUser: data.loginUser });
            this.setState({ pwdUser: data.pwdUser });
            this.setState({ nomUser: data.nomUser });
            this.setState({ telUser: data.telUser });
            this.setState({ emailUser: data.emailUser });
            this.setState({ idProgHor: data.idProgHor.idProgHor });
            this.setState({ actifUser: data.actifUser });
            this.setState({ idGrp: data.idGrp.idGrp });
            this.setState({ idLoc: data.idLoc.idLoc });
            this.setState({ idSrv: data.idSrv.idSrv });
            this.setState({ photoUser: data.photoUser });
            this.setState({ matrUser: data.matrUser });

            this.setState({ smaritalUser: data.smaritalUser, });
            this.setState({ sexeUser: data.sexeUser });
            this.setState({ dateNaissUser: moment(this.state.dateNaissUser, 'DD/MM/YYYY').format('DD/MM/YYYY') });

            this.setState({ lieuNaisslUser: data.lieuNaisslUser });
            this.setState({ dateEmbUser: moment(this.state.dateEmbUser, 'DD/MM/YYYY').format('DD/MM/YYYY') });
            this.setState({ dateDepUser: moment(this.state.dateDepUser, 'DD/MM/YYYY').format('DD/MM/YYYY') });
            this.setState({ motifDepUser: data.motifDepUser });
            this.setState({ niveauEtudUser: data.niveauEtudUser });
            this.setState({ hierarchieUser: data.hierarchieUser });

            this.setState({ modif: true });
            this.setState({ selectedUser: data });


            window.scrollTo(0, 0);
        }
    }

    annule_hie() {
        this.setState({ idHie: 0 });
        this.setState({ finHie: '' });
        this.setState({ debHie: '' });
        this.setState({ modif_hie: false });

        this.forceUpdate();
    }

    annule_user() {
        this.setState({ loginUser: '' });
        this.setState({ pwdUser: '' });
        this.setState({ nomUser: '' });
        this.setState({ telUser: '' });
        this.setState({ emailUser: '' });
        this.setState({ matrUser: '' });
        this.setState({ photoUser: 'nophoto.png' });
        this.setState({ file: null });
        this.setState({ modif: false });
        this.setState({ dateNaissUser: '' });
        this.setState({ lieuNaisslUser: '' });
        this.setState({ dateEmbUser: '' });
        this.setState({ dateDepUser: '' });
        this.forceUpdate();
    }

    enreg_user() {
        if (this.state.idProgHor == '' || this.state.idProgHor == null || this.state.dateEmbUser == null || this.state.dateEmbUser == '' || this.state.loginUser == '' || this.state.dateNaissUser == '' || this.state.smaritalUser == null || this.state.smaritalUser == '' || this.state.sexeUser == '' || this.state.sexeUser == null || this.state.idProgHor == '' || this.state.idProgHor == null || this.state.nomUser == '' || this.state.actifUser == null || this.state.actifUser == '' || this.state.idSrv == null || this.state.idSrv == '' || this.state.idGrp == null || this.state.idGrp == '' || this.state.idLoc == null || this.state.idLoc == '' || this.state.matrUser == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let upload = false;
            let nomfic = this.state.photoUser;
            if (this.state.file !== null && this.state.file !== undefined) {
                let name = this.state.file.name;
                nomfic = this.state.loginUser + '.' + name.slice((name.lastIndexOf(".") - 1 >>> 0) + 2);
                upload = true;
            }
            if (this.state.modif == false) {
                this.setState({ showindicator: true });
                let data = { idUser: "0", dateEmbUser: moment(this.state.dateEmbUser, 'DD/MM/YYYY').format('DD/MM/YYYY'), lieuNaisslUser: this.state.lieuNaisslUser, motifDepUser: this.state.motifDepUser, hierarchieUser: this.state.hierarchieUser.value, smaritalUser: this.state.smaritalUser, niveauEtudUser: this.state.niveauEtudUser, nomUser: this.state.nomUser, loginUser: this.state.loginUser, telUser: this.state.telUser, emailUser: this.state.emailUser, matrUser: this.state.matrUser, actifUser: this.state.actifUser, photoUser: nomfic, idGrp: { idGrp: this.state.idGrp }, idLoc: { idLoc: this.state.idLoc }, idSrv: { idSrv: this.state.idSrv }, idProgHor: { idProgHor: this.state.idProgHor }, dateNaissUser: moment(this.state.dateNaissUser, 'DD/MM/YYYY').format('DD/MM/YYYY'), sexeUser: this.state.sexeUser };
                this.myservice.enreg_user(data).then(rep => {
                    if (rep != undefined && rep != null) {
                        if (upload == true) {
                            this.fileUpload(this.state.file, nomfic).then((response) => {
                                console.log(response.data);
                            })
                        }
                        if (rep.code == 200) {
                            data.idUser = rep.idRef;
                            this.new_sync(data);
                        }
                        this.resultat(rep.code, rep.contenu);
                    }
                });
            } else {
                let data = this.state.selectedUser;
                var fin = moment(this.state.dateDepUser, 'DD/MM/YYYY').format('DD/MM/YYYY');
                if (fin == 'Invalid date') {
                    fin = null;
                }
                this.setState({ showindicator: true });
                data.dateEmbUser = moment(this.state.dateEmbUser, 'DD/MM/YYYY').format('DD/MM/YYYY');
                data.dateDepUser = fin;
                data.nomUser = this.state.nomUser;
                data.loginUser = this.state.loginUser;
                data.telUser = this.state.telUser;
                data.emailUser = this.state.emailUser;
                data.sexeUser = this.state.sexeUser;
                data.matrUser = this.state.matrUser;
                data.actifUser = this.state.actifUser;
                data.lieuNaisslUser = this.state.lieuNaisslUser;
                data.smaritalUser = this.state.smaritalUser;
                data.niveauEtudUser = this.state.niveauEtudUser;
                data.hierarchieUser = this.state.hierarchieUser;
                data.motifDepUser = this.state.motifDepUser;
                data.idGrp = { idGrp: this.state.idGrp };
                data.idLoc = { idLoc: this.state.idLoc };
                data.idProgHor = { idProgHor: this.state.idProgHor };
                data.photoUser = nomfic;
                this.myservice.enreg_user(data).then(data => {
                    if (data != undefined && data != null) {
                        if (upload == true) {
                            this.fileUpload(this.state.file, nomfic).then((response) => {
                                console.log(response.data);
                            })
                        }
                        this.resultat(data.code, data.contenu);
                    }
                });
            }
        }
    }

    enreg_hie() {
        if (this.state.debHie == "" || this.state.user_resp == null || this.state.user_resp == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            var deb = moment(this.state.debHie, 'DD/MM/YYYY').format('DD/MM/YYYY');
            var fin = moment(this.state.finHie, 'DD/MM/YYYY').format('DD/MM/YYYY');
            if (fin == 'Invalid date') {
                fin = null;
            }
            if (this.state.modif_hie == false) {
                this.setState({ showindicator: true });
                let data = { idHie: "0", idUser: this.state.selectedUser, idResp: { idUser: this.state.user_resp }, debHie: deb, finHie: fin, numHie: this.state.numHie, actifHie: this.state.actifHie };
                this.myservice.enreg_hie(data).then(data => {
                    if (data != undefined && data != null) {
                        this.resultat_hie(data.code, data.contenu);
                    }
                });
            } else {
                var deb = moment(this.state.debHie, 'DD/MM/YYYY').format('DD/MM/YYYY');
                var fin = moment(this.state.finHie, 'DD/MM/YYYY').format('DD/MM/YYYY');
                if (fin == 'Invalid date') {
                    fin = null;
                }
                this.setState({ showindicator: true });
                var data = this.state.selectedhie;
                data.debHie = deb;
                data.finHie = fin;
                data.numHie = this.state.numHie;
                data.idResp = { idUser: this.state.user_resp };
                this.myservice.enreg_hie(data).then(data => {
                    if (data != undefined && data != null) {
                        this.resultat_hie(data.code, data.contenu);
                    }
                });
            }
        }
    }


    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 120 }}>
            <div className="p-col-3"><Button onClick={(e) => this.edit_user(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col-3"><Button onClick={(e) => this.conf_delete_user(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
            <div className="p-col-3"><Button onClick={(e) => this.aff_dlg_hie(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-sitemap" /></div>
            <div className="p-col-3"><Button onClick={(e) => this.conf_sync(rowData)} className="p-button-secondary" style={{ width: 25, height: 25 }} icon="pi pi-check-square" /></div>
        </div>
        //<SplitButton label="Modifier" style={{ width: '105px' }} onClick={(e) => this.edit_user()} icon="pi pi-pencil" model={this.state.items} />
    }

    actionTemplate_hie(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_hie(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
        </div>
    }

    delete_user() {
        if (this.state.selectedUser == null || this.state.selectedUser == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sÈlectionner la ligne ‡ supprimer' });
        } else {
            this.myservice.supr_user(this.state.selectedUser.idUser).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_user(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedUser: data });
            this.setState({ msg: data.nomUser });
            this.setState({ visible: true });
        }
    }

    aff_dlg_hie(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedUser: data }, () => {
                this.get_liste_hie();
                this.setState({ visible_hie: true });
            });
        }
    }


    photoTemplate(rowData, column) {
        var src = this.myservice.download_file(rowData.photoUser, 'photos');
        return <img src={src} alt={rowData.nomUser} width="45px" height="45px" />;
    }

    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_user();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        let lservice = null;
        let lsite = null;
        let lgroupe = null;
        if (this.state.liste_srv != null && this.state.liste_srv != undefined) {
            lservice = this.state.liste_srv.map(x => {
                return { value: x.idSrv, label: x.nomSrv }
            });
        }
        if (this.state.liste_site.length > 0) {
            lsite = this.state.liste_site.map(x => {
                return { value: x.idLoc, label: x.nomLoc }
            });
        }
        if (this.state.liste_groupe != null && this.state.liste_groupe != undefined) {
            lgroupe = this.state.liste_groupe.map(x => {
                return { value: x.idGrp, label: x.nomGrp }
            });
        }

        let luser = null;
        if (this.state.liste_users != null && this.state.liste_users != undefined && this.state.liste_users.length > 0) {
            luser = this.state.liste_users.map(x => {
                return { value: x.idUser, label: x.nomUser + ' ' + x.matrUser, matrUser: x.matrUser }
            });
        }

        let lprogh = this.state.liste_progh.map(x => {
            return { value: x.idProgHor, label: x.libProgHor }
        });

        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Hierarchie" visible={this.state.visible_hie} style={{ width: '800px' }} modal={true} onHide={(e) => this.setState({ visible_hie: false })}>

                    <div className="p-grid">
                        <div className="p-col-12">

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="loginUser">Date début *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.debHie} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ debHie: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomUser">Date fin</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.finHie} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ finHie: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Responsable *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={luser} value={this.state.user_resp} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ user_resp: e.value })} autoWidth={false} panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="actifUser">Actif *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.actifs} value={this.state.actifHie} onChange={(e) => this.setState({ actifHie: e.value })} autoWidth={false} optionLabel='label' />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="actifUser">Hierarchie *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_numHie} value={this.state.numHie} onChange={(e) => this.setState({ numHie: e.value })} autoWidth={false} optionLabel='label' />
                                </div>

                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_hie()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_hie()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_hie} paginatorPosition="top" selectionMode="single" paginator={true} rows={5}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedhie: e.data })} onRowSelect={e => this.setState({ selectedhie: e.data })}
                                responsive={true} selection={this.state.selectedhie} >
                                <Column header="#" body={this.actionTemplate_hie.bind(this)} style={{ width: '8%' }} />
                                <Column field="idResp.nomUser" header="Responsable" style={{ width: '40%' }} />
                                <Column field="idResp.matrUser" header="Matricule" style={{ width: '12%' }} />
                                <Column field="numHie" header="Hierar." style={{ width: '10%' }} />
                                <Column field="debHie" header="Date début" style={{ width: '20%' }} />
                                <Column field="finHie" header="Date fin" style={{ width: '20%' }} />
                            </DataTable>

                        </div>
                    </div>
                </Dialog>




                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{}}>

                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="loginUser">Login *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="loginUser" value={this.state.loginUser} onChange={(e) => this.setState({ loginUser: e.target.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomUser">Nom *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="nomUser" value={this.state.nomUser} onChange={(e) => this.setState({ nomUser: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="nomUser">Matricule * </label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.matrUser} onChange={(e) => this.setState({ matrUser: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="telephone">Telephone</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="telUser" value={this.state.telUser} onChange={(e) => this.setState({ telUser: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText id="email" value={this.state.emailUser} onChange={(e) => this.setState({ emailUser: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Groupe *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={lgroupe} value={this.state.idGrp} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ idGrp: e.value })} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Service *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={lservice} value={this.state.idSrv} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ idSrv: e.value })} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">Site *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={lsite} value={this.state.idLoc} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({ idLoc: e.value })} autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="actifUser">Actif *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.actifs} value={this.state.actifUser} onChange={(e) => this.setState({ actifUser: e.value })} autoWidth={false} optionLabel='label' />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="photoUser">Photo</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <input type="file" onChange={this.onChange} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Programme horaire *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown value={this.state.idProgHor} options={lprogh} onChange={(e) => { this.setState({ idProgHor: e.value }) }} optionLabel="label" optionValue="value" autoWidth={false} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="descCodif">Date naissance *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Calendar value={this.state.dateNaissUser} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ dateNaissUser: e.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="email">Lieu naissance</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText value={this.state.lieuNaisslUser} onChange={(e) => this.setState({ lieuNaisslUser: e.target.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="actifUser">Sexe *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_sexeUser} value={this.state.sexeUser} onChange={(e) => this.setState({ sexeUser: e.value })} autoWidth={false} optionLabel='label' />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="actifUser">Hierarchie *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_hierarchie_user} value={this.state.hierarchieUser} onChange={(e) => this.setState({ hierarchieUser: e.value })} autoWidth={false} optionLabel='label' />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="actifUser">Niveau d'étude *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={this.state.liste_niveau_etude} value={this.state.niveauEtudUser} onChange={(e) => this.setState({ niveauEtudUser: e.value })} autoWidth={false} optionLabel='label' />
                                </div>
                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_user()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_user()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_users} paginatorPosition="top" selectionMode="single" paginator={true} rows={10}
                                alwaysShowPaginator={false}
                                onRowClick={e => this.setState({ selectedUser: e.data })} onRowSelect={e => this.setState({ selectedUser: e.data })}
                                responsive={true} selection={this.state.selectedUser} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '9%' }} />
                                <Column header="Photo" body={this.photoTemplate.bind(this)} style={{ width: '5%' }} />
                                <Column field="nomUser" filter={true} filterMatchMode='contains' header="Nom" sortable={true} style={{ width: '20%' }} />
                                <Column field="loginUser" filter={true} filterMatchMode='contains' header="Login" sortable={true} style={{ width: '9%' }} />
                                <Column field="idGrp.nomGrp" filter={true} filterMatchMode='contains' header="Groupe" sortable={true} style={{ width: '14%' }} />
                                <Column field="actifUser" header="Actif" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '6%' }} />
                                <Column field="telUser" header="Telephone" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                <Column field="idLoc.nomLoc" header="Site" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                                <Column field="idSrv.nomSrv" header="Service" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '13%' }} />
                            </DataTable>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}