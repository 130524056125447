import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
 
export class Form_quartier extends Component {
    constructor() {
        super();
        this.state = {
            rnomQuartier: '',
            rcrd: '',
            rpref: '',
            rregion: '',
            nomQuart: '',
            id_crd: 0,
            idCrd: null,
            idQuart: 0,
            liste_quart: [],
            liste_crd: [],
            showindicator: false,
            selectedquartier: null,
            groupe: null,
            site: null,
            service: null,
            msg: '',
            modif: false,
            visible: false
        };
        this.edit_quartier = this.edit_quartier.bind(this);
        this.conf_delete_quartier = this.conf_delete_quartier.bind(this);
        this.resultat=this.resultat.bind(this);
        this.myservice = new Digipointservice();
    }

    componentDidMount() {
        this.get_liste_quart();
        this.myservice.get_liste_crd().then(data => this.setState({ liste_crd: data }));
    }

    get_liste_quart() {
        this.setState({ showindicator: true });
        this.myservice.get_liste_quartier(this.state.rnomQuartier, this.state.rpref, this.state.rcrd, this.state.rregion).then(data => this.setState({ liste_quart: data,showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.get_liste_quart();
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }
 
    edit_quartier(data) {
        if (data != null && data != undefined) {
            this.setState({ id_quart: data.idQuart });
            this.setState({ nomQuart: data.nomQuart });
            this.setState({ idCrd:  data.idCrd.idCrd});
            this.setState({ modif: true });
            this.setState({ selectedquartier: data });
            window.scrollTo(0, 0);
        }
    }

    annule_quartier() {
        this.setState({ idQuart: 0 });
        this.setState({ nomQuart: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_quartier() {
        if (this.state.nomQuart == "" ||  this.state.idCrd == null || this.state.idCrd == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let data={idQuart: 0,nomQuart: this.state.nomQuart,idCrd: {idCrd: this.state.idCrd}};
                this.myservice.enreg_quartier(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                var data=this.state.selectedquartier;
                data.nomQuart=this.state.nomQuart;
                data.idCrd={idCrd: this.state.idCrd};
                this.myservice.enreg_quartier(data).then(data => {
                    this.resultat(data.code, data.contenu);
                });
            }
        }
    }

    actionTemplate(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_quartier(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_quartier(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_quartier() {
        if (this.state.selectedquartier == null || this.state.selectedquartier == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sÈlectionner la ligne ‡ supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.myservice.supr_quartier(this.state.selectedquartier).then(data => this.resultat(data.code, data.contenu));
        }
    }

    conf_delete_quartier(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedquartier: data });
            this.setState({ msg: data.nomQuart });
            this.setState({ visible: true });
        }
    }


    render() {
        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_quartier();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        let lcrd = null;
        if(this.state.liste_crd!=null && this.state.liste_crd!=undefined){
            lcrd = this.state.liste_crd.map(x => {
                return { value: x.idCrd, label: x.nomCrd+' / '+x.idPref.nomPref,idPref: x.idPref.idPref,nomPref: x.idPref.nomPref}
            });
        }
       
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={(e) => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ height: 1000 }}>
                           
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <label >Nom quartier *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <InputText  value={this.state.nomQuart} onChange={(e) => this.setState({ nomQuart: e.target.value })} />
                                </div>
                             
                                <div className="p-col-12 p-md-2">
                                    <label htmlFor="profile">CRD /commune *</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <Dropdown options={lcrd} value={this.state.idCrd} optionLabel='label' filter={true} filterBy='label' onChange={(e) => this.setState({idCrd: e.value })} autoWidth={false} />
                                </div>
                            </div>

                            <center>
                                <div className="p-grid" style={{ width: 300 }}>
                                    <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_quartier()} /></div>
                                    <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_quartier()} /></div>
                                </div>
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <DataTable value={this.state.liste_quart} paginatorPosition="top" selectionMode="single" paginator={true} rows={15}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedquartier: e.data })} onRowSelect={e => this.setState({ selectedquartier: e.data })}
                                responsive={true} selection={this.state.selectedquartier} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                <Column field="nomQuart" filter={true} filterMatchMode='contains' header="Nom quartier" sortable={true} style={{ width: '35%' }} />
                                <Column field="idCrd.nomCrd" body={(rowData, column) => {
                                    let lib = '';
                                    if (rowData.idCrd.nomCrd !== undefined && rowData.idCrd.nomCrd !== null) {
                                        lib = rowData.idCrd.nomCrd;
                                    }
                                    return <div>
                                        <span style={{ color: '#000' }}>{lib}</span>
                                    </div>
                                }} filter={true} filterMatchMode='contains' header="CRD" sortable={true} style={{ width: '27%' }} />
                                <Column field="idCrd.idPref.nomPref" body={(rowData, column) => {
                                    let lib = '';
                                    if (rowData.idCrd.idPref !== undefined && rowData.idCrd.idPref !== null) {
                                        lib = rowData.idCrd.idPref.nomPref;
                                    }
                                    return <div>
                                        <span style={{ color: '#000' }}>{lib}</span>
                                    </div>
                                }} filter={true} filterMatchMode='contains' header="Prefecture" sortable={true} style={{ width: '15%' }} />
                                <Column field="idCrd.idPref.region" body={(rowData, column) => {
                                    let lib = '';
                                    if (rowData.idCrd.idPref !== undefined && rowData.idCrd.idPref !== null) {
                                        lib = rowData.idCrd.idPref.region;
                                    }
                                    return <div>
                                        <span style={{ color: '#000' }}>{lib}</span>
                                    </div>
                                }} header="Region" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '15%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}