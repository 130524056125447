import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { Panel } from 'primereact/panel';
import { Chart } from 'primereact/chart';
import { ProgressSpinner } from 'primereact/progressspinner';

import { RadialGauge } from 'react-canvas-gauges';
//react-canvas-gauges

 
export class Dashboard_agent extends Component {

    constructor() {
        super();
        this.state = {
            liste_retard: [],
            liste_st_retard_suplem: [],
            liste_suplem: [],
            liste_abs: [],
            liste_pointage: [],
            liste_dept: [],
            nb_presence: 0,
            nb_absence: 0,
            nbh_retard: 0,
            nbh_suplem: 0,
            nbh_travail: 0,
            nbh_travail_prev: 0,
            taux_travail: 0,
            dept: null,
            rid_dept: '',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            showindicator: false
        };
        this.myservice = new Digipointservice();
    }

    charger_dashboard(id_dept) {
        this.setState({ showindicator: true });
        this.myservice.get_dashboard(new Intl.DateTimeFormat('en-GB').format(new Date()), id_dept).then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_st_retard_suplem: data.liste_st_retard_suplem });
                this.setState({ nb_presence: data.nb_presence });
                this.setState({ nb_absence: data.nb_absence });
                this.setState({ nbh_retard: data.nbh_retard });
                this.setState({ nbh_suplem: data.nbh_suplem });
                this.setState({ taux_travail: data.taux_travail });
            }
            this.setState({ showindicator: false });
        }
        );
    }

    componentDidMount() {
       
        this.charger_dashboard('-1');
    }


    render() {
        let liste_label = null;
        let liste_ds_retard = null;
        let liste_ds_suplem = null;
        if (this.state.liste_st_retard_suplem != null && this.state.liste_st_retard_suplem != undefined && this.state.liste_st_retard_suplem.length > 0) {
            liste_label = this.state.liste_st_retard_suplem.map(x => {
                return x.mois
            });

            liste_ds_retard = this.state.liste_st_retard_suplem.map(x => {
                return x.nbh_retard
            });

            liste_ds_suplem = this.state.liste_st_retard_suplem.map(x => {
                return x.nbh_suplem
            });
        }

        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    label: 'Heures suplémentaires',
                    backgroundColor: '#669966',
                    borderColor: '#669966',
                    fill: false,
                    data: liste_ds_suplem
                },
                {
                    label: 'Heures retards',
                    backgroundColor: '#FC5146',
                    borderColor: '#FC5146',
                    fill: false,
                    data: liste_ds_retard
                }
            ]
        };



        return <div className="p-grid dashboard" style={{ marginTop: -22 }}>
           
            <div className="p-col-12 p-md-3">
                <div className="overview-box overview-box-1"><h1>NB PRESENCE</h1>
                    <div className="overview-value">{this.state.nb_presence}</div>
                    <img src="assets/layout/images/dashboard/graph-blue.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-12 p-md-3">
                <div className="overview-box overview-box-2">
                    <h1>NB ABSENCE</h1>
                    <div className="overview-value">{this.state.nb_absence}</div>
                    <img src="assets/layout/images/dashboard/graph-green.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-12 p-md-3">
                <div className="overview-box overview-box-3">
                    <h1>RETARDS</h1>
                    <div className="overview-value">{this.state.nbh_retard} H</div>
                    <img src="assets/layout/images/dashboard/graph-yellow.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-12 p-md-3">
                <div className="overview-box overview-box-4">
                    <h1>HEURES SUPPLEMENTAIRES</h1>
                    <div className="overview-value">{this.state.nbh_suplem} H</div>
                    <img src="assets/layout/images/dashboard/graph-red.svg" alt="apollo-layout" />
                </div>
            </div>

            <div className="p-col-12 p-md-6 p-lg-3 task-list">
                <Panel header="TAUX DE TRAVAIL DU MOIS" style={{ minHeight: '415px' }}>
                    <RadialGauge
                        units='%'
                        title='Taux de travail'
                        value={this.state.taux_travail}
                        minValue={0}
                        maxValue={100}
                        colorValueBoxShadow={false}
                        highlights={[
                            { from: 0, to: 60, color: 'rgba(255,0,0,.60)' },
                            { from: 60, to: 85, color: 'rgba(255,255,0,.60)' },
                            { from: 85, to: 100, color: 'rgba(0,128,0,.60)' }
                        ]}
                        majorTicks={['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100']}
                        minorTicks={2}
                    />
                </Panel>
            </div>

            <div className="p-col-12 p-md-6 p-lg-9 p-fluid contact-form">
                <Panel header="STATISTIQUES DES RETARDS (ANNEE EN COURS)" style={{ minHeight: '415px' }}>
                <Chart type="line" data={chart_data} height="100w" />
                </Panel>
            </div>

        </div>
    }
}