import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import 'moment';
import 'moment/locale/fr';
import { Button } from 'primereact/button';
import './styles.css';

export class Form_divservice extends Component {
    constructor() {
        super();
        this.state = {
            liste_srv: [],
            liste_dept: [],
            liste_div: [],
            selectedsrv: null,
            selecteddiv: null,
            idDept: null,
            nomDiv: '',
            codeDiv: '',
            nomSrv: '',
            codeSrv: '',
            msg: '',
            modif_srv: false,
            modif_div: false,
            visible_div: false,
            visible_srv: false,

            chef_srv: null,
            chef_div: null,
            showindicator: false,
        };
        this.edit_srv = this.edit_srv.bind(this);
        this.conf_delete_srv = this.conf_delete_srv.bind(this);

        this.edit_div = this.edit_div.bind(this);
        this.conf_delete_div = this.conf_delete_div.bind(this);

        this.myservice = new Digipointservice();
    }


    rechliste_service() {
        this.setState({ showindicator: true });
        this.myservice.getListe_srv_by_div(this.state.selecteddiv.id_div).then(data => this.setState({ liste_srv: data, showindicator: false }));
        this.forceUpdate();
    }

    componentDidMount() {
        this.setState({ showindicator: true });
        this.myservice.getListe_div().then(data => this.setState({ liste_div: data, showindicator: false }));
        this.myservice.getListe_dept().then(data => this.setState({ liste_dept: data }));
        this.myservice.getListe_user().then(data => this.setState({ liste_user: data }));
    }

    resultat(code, contenu) {
        if (code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_srv();
            this.annule_div();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_srv(data) {
        if (data != null && data != undefined) {
            this.setState({ nomSrv: data.nom_srv });
            this.setState({ codeSrv: data.code_srv });
            this.setState({ modif_srv: true });
            this.setState({ selectedsrv: data });
            this.setState({ chef_srv: data.chef_service.idUser  })
            window.scrollTo(0, 0);
        }
    }

    edit_div(data) {
        if (data != null && data != undefined) {
            this.setState({ nomDiv: data.nom_div });
            this.setState({ codeDiv: data.code_div });
            this.setState({ modif_div: true });
            this.setState({ selecteddiv: data });
            this.setState({ idDept:  data.departement.id_depart});
            this.setState({ chef_div:  data.chef_division.idUser })
            window.scrollTo(0, 0);
            this.forceUpdate();
        }
    }

    annule_srv() {
        this.setState({ codeSrv: '' });
        this.setState({ nomSrv: '' });
        this.setState({ modif_srv: false });
        this.forceUpdate();
    }

    annule_div() {
        this.setState({ nomDiv: '' });
        this.setState({ codeDiv: '' });
        this.setState({ modif_div: false });
        this.forceUpdate();
    }

    enreg_srv() {
        if (this.state.nomSrv == "" || this.state.chef_srv == null || this.state.chef_srv == undefined || this.state.codeSrv == "" || this.state.selecteddiv == null || this.state.selecteddiv == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires et sélectionner une division' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif_srv == false) {
                let data = { idSrv: '0', nomSrv: this.state.nomSrv, codeSrv: this.state.codeSrv, idDiv: { idDiv: this.state.selecteddiv.id_div }, idUserResp: this.state.chef_srv };
                this.myservice.enreg_srv(data).then(data => {
                    this.resultat(data.code, data.contenu);
                    this.rechliste_service();
                });
            } else {
                let data = this.state.selecteddiv;
                data.nomSrv = this.state.nomSrv;
                data.codeSrv = this.state.codeSrv;
                data.idUserResp = this.state.chef_srv;
                this.myservice.enreg_srv(data).then(data => {
                    this.resultat(data.code, data.contenu);
                    this.rechliste_service();
                });
            }
        }
    }


    enreg_div() {
        if (this.state.nomDiv == "" || this.state.chef_div == null || this.state.chef_div == undefined || this.state.codeDiv == "" || this.state.idDept == null || this.state.idDept == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif_div == false) {
                let data = { idDiv: '0', nomDiv: this.state.nomDiv, codeDiv: this.state.codeDiv, idDept: { idDept: this.state.idDept }, idUserResp: this.state.chef_div };
                this.myservice.enreg_div(data).then(data => this.resultat(data.code, data.contenu));
            } else {
                let data = this.state.selecteddiv;
                data.nomDiv = this.state.nomDiv;
                data.codeDiv = this.state.codeDiv;
                data.idUserResp = this.state.chef_div;
                data.idDept = { idDept: this.state.idDept };
                this.myservice.enreg_div(data).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }


    actionTemplate_srv(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_srv(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_srv(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_div(rowData, column) {
        return <div className="p-grid" style={{ width: 90 }}>
            <div className="p-col"><Button onClick={(e) => this.edit_div(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="p-col"><Button onClick={(e) => this.conf_delete_div(rowData)} className="p-button-danger" style={{ width: 25, height: 25 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_srv() {
        if (this.state.selectedsrv == null || this.state.selectedsrv == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            let data = { idSrv: this.state.selectedsrv.id_srv, idDiv: { idDiv: this.state.selecteddiv.id_div } };
            this.myservice.supr_srv(data).then(data => {
                this.resultat(data.code, data.contenu);
                this.rechliste_service();
            });
        }
    }


    conf_delete_srv(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedsrv: data });
            this.setState({ msg: data.nomSrv });
            this.setState({ visible_srv: true });
        }
    }

    delete_div() {
        if (this.state.selecteddiv == null || this.state.selecteddiv == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            let data = { idDiv: this.state.selecteddiv.id_div };
            this.myservice.supr_div(data).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_div(data) {
        if (data != null && data != undefined) {
            this.setState({ selecteddiv: data });
            this.setState({ msg: data.nomDiv });
            this.setState({ visible_div: true });
        }
    }


    render() {
        let ldepts = this.state.liste_dept.map(x => {
            return { value: x.idDept, label: x.nomDept }
        });

        let luser = null;
        if (this.state.liste_user != null && this.state.liste_user != undefined && this.state.liste_user.length > 0) {
            let l1 = this.state.liste_user.map(x => {
                return { value: x.idUser, label: x.nomUser + ' ' + x.matrUser }
            });
            let l2 = [{ value: '-1', label: 'AUCUN RESPONSABLE POUR LE MOMENT -1' }];
            luser = l2.concat(l1);
        }

        const dialogFooter_srv = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_srv: false });
                    this.delete_srv();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_srv: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooter_div = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_div: false });
                    this.delete_div();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_div: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible_srv} style={{ width: '250px' }} modal={true} footer={dialogFooter_srv} onHide={(e) => this.setState({ visible_srv: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer ce service <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <Dialog header="Confirmation" visible={this.state.visible_div} style={{ width: '250px' }} modal={true} footer={dialogFooter_div} onHide={(e) => this.setState({ visible_div: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer cette division <b>{this.state.msg} </b>?
                    </div>
                </Dialog>

                <div className="p-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="card card-w-title" style={{ height: 700 }}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <InputText value={this.state.nomDiv} placeholder='Nom division' onChange={(e) => this.setState({ nomDiv: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <InputText value={this.state.codeDiv} placeholder='Code division' onChange={(e) => this.setState({ codeDiv: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <Dropdown value={this.state.idDept} options={ldepts} onChange={(e) => {
                                                this.setState({ idDept: e.value });
                                            }
                                            } filter={true} filterBy='label' optionLabel="label" style={{ width: '100%' }} placeholder="Département" panelStyle={{ width: '400px' }} />
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <Dropdown options={luser} placeholder='Choisir le responsable' filter={true} filterMatchMode='contains' filterBy='label' value={this.state.chef_div} optionLabel='label' onChange={event => this.setState({ chef_div: event.value })} style={{ width: '100%' }} autoWidth={false} panelStyle={{ width: '400px' }} />
                                        </div>
                                    </div>

                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_div()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_div()} /></div>
                                        </div>
                                    </center>

                                    <DataTable value={this.state.liste_div} paginatorPosition="top" selectionMode="single" paginator={true} rows={10}
                                        alwaysShowPaginator={false} onRowClick={e => this.setState({ selecteddiv: e.data })}
                                        onRowSelect={e => {
                                            this.setState({ selecteddiv: e.data }, () => {
                                                this.rechliste_service();
                                            });
                                        }
                                        } className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                        responsive={true} selection={this.state.selecteddiv} >
                                        <Column header="Action" body={this.actionTemplate_div.bind(this)} style={{ width: '14%' }} />
                                        <Column field="nom_div" header="Nom division" sortable={true} style={{ width: '46%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="code_div" header="Code" sortable={true} style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="departement.code_depart" header="Département" sortable={true} style={{ width: '25%' }} filter={true} filterMatchMode='contains' />
                                    </DataTable>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="p-col-12 p-lg-6">

                        <div className="card card-w-title" style={{ height: 700 }}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <InputText value={this.state.nomSrv} placeholder='Nom service' onChange={(e) => this.setState({ nomSrv: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <InputText value={this.state.codeSrv} placeholder='Code service' onChange={(e) => this.setState({ codeSrv: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-6 p-lg-4">
                                            <Dropdown options={luser} placeholder='Choisir le responsable' filter={true} filterMatchMode='contains' filterBy='label' value={this.state.chef_srv} optionLabel='label' onChange={event => this.setState({ chef_srv: event.value })} autoWidth={false} panelStyle={{width: 400}} />
                                        </div>
                                    </div>

                                    <center>
                                        <div className="p-grid" style={{ width: 300 }}>
                                            <div className="p-col"><Button label="Annuler" icon="pi pi-ban" onClick={(e) => this.annule_srv()} /></div>
                                            <div className="p-col"><Button label="Valider" icon="pi pi-check" onClick={(e) => this.enreg_srv()} /></div>
                                        </div>
                                    </center>

                                    <DataTable value={this.state.liste_srv} paginatorPosition="top" selectionMode="single" paginator={true} rows={10}
                                        alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedsrv: e.data })} onRowSelect={e => this.setState({ selectedsrv: e.data })}
                                        responsive={true} selection={this.state.selectedsrv} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                        <Column header="Action" body={this.actionTemplate_srv.bind(this)} style={{ width: '20%' }} />
                                        <Column field="nom_srv" header="Nom service" sortable={true} style={{ width: '55%' }} />
                                        <Column field="code_srv" header="Code" sortable={true} style={{ width: '25%' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}