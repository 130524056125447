import React, { useState } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppBreadcrumb from './AppBreadcrumb';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppConfig from './AppConfig';
import Digipointservice from './service/Digipointservice';
import { Dashboard } from './components/Dashboard';
import { Form_etablissement } from './components/Form_etablissement';
import { Form_horaire } from './components/Form_horaire';
import { Form_groupe } from './components/Form_groupe';
import { Form_depart } from './components/Form_depart';
import { Form_divservice } from './components/Form_divservice';
import { Form_users } from './components/Form_users';
import { Form_localite } from './components/Form_localite';
import { Form_pointage } from './components/Form_pointage';
import { Form_absence } from './components/Form_absence';
import { Form_edition_pointage } from './components/Form_edition_pointage';
import { Form_situation_pointage } from './components/Form_situation_pointage';
import { Form_situation_pointage_srv } from './components/Form_situation_pointage_srv';
import { Form_situation_pointage_div } from './components/Form_situation_pointage_div';
import { Form_situation_pointage_dept } from './components/Form_situation_pointage_dept';
import { Form_tranche_retard } from './components/Form_tranche_retard';
import { Form_organigramme } from './components/Form_organigramme';
import { Form_quartier } from './components/Form_quartier';
import { Dashboard_agent } from './components/Dashboard_agent';
import { Form_periode_objectif } from './components/Form_periode_objectif';
import { Form_objectif } from './components/Form_objectif';
import { Form_Msg } from './components/Form_Msg';
import { FormChangepwd } from './components/FormChangepwd';
import { Form_MsgInbox } from './components/Form_MsgInbox';

import PrimeReact from 'primereact/api';

import { ProgressBar } from 'primereact/progressbar'

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const App = () => {

    const [layoutMode, setLayoutMode] = useState('horizontal');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [menuActive, setMenuActive] = useState(false);
    const [themeColor, setThemeColor] = useState('blue');
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [scheme, setScheme] = useState('light');

    let menuClick;
    let topbarItemClick;

    const appservice = new Digipointservice();
    var menu = [];
    var profil_suer = '';
    var userconnected = appservice.get_userconnected();

    if (userconnected !== null && userconnected !== undefined && userconnected !== '') {
        profil_suer = userconnected.idGrp.nomGrp;
        console.log(profil_suer);
    }

    if (profil_suer == 'AGENT') {
        menu = [
            {
                label: 'Favorites', icon: 'pi pi-fw pi-home',
                items: [
                    { label: 'Accueil', icon: 'pi pi-fw pi-dashboard', to: '/accueil' },
                    { label: 'Pointages', icon: 'pi pi-fw pi-book', command: () => { window.location = "#/pointages" } },
                    { label: 'Absences', icon: 'pi pi-fw pi-clock-o', command: () => { window.location = "#/Absences" } },
                    { label: 'Organigramme', icon: 'pi pi-fw pi-sitemap', command: () => { window.location = "#/Organigramme" } },
                    {
                        label: 'Déconnexion', icon: 'pi pi-fw pi-sign-out', command: () => {
                            localStorage.removeItem('userconnected');
                            window.location = "/#login";
                        }
                    }
                ]
            }

        ];
    } else if (profil_suer == 'DIRECTEUR' || profil_suer == 'CHEF SERVICE' || profil_suer == 'CHEF DIVISION') {
        menu = [
            {
                label: 'Favorites', icon: 'pi pi-fw pi-home',
                items: [
                    { label: 'Dashboard', icon: 'pi pi-fw pi-dashboard', to: '/dashboard' },
                    { label: 'Gestion pointage', icon: 'pi pi-fw pi-book', command: () => { window.location = "#/pointages" } },
                    { label: 'Gestion absences', icon: 'pi pi-fw pi-clock-o', command: () => { window.location = "#/Absences" } },
                    {
                        label: 'Editions et stats', icon: 'pi pi-fw pi-area-chart',
                        items: [
                            { label: 'Liste pointage', icon: 'pi pi-fw pi-table', to: '/liste_pointage' },
                            { label: 'Situation pointage', icon: 'pi pi-fw pi-table', to: '/Situation_pointages' },
                            { label: 'Volume H / division', icon: 'pi pi-fw pi-chart-line', to: '/Situation_pointages_division' },
                            { label: 'Volume H / service', icon: 'pi pi-fw pi-chart-line', to: '/Situation_pointages_service' },
                            { label: 'Organigramme', icon: 'pi pi-fw pi-sitemap', to: '/Organigramme' }
                        ]
                    },
                    {
                        label: 'Déconnexion', icon: 'pi pi-fw pi-sign-out', command: () => {
                            localStorage.removeItem('userconnected');
                            window.location = "/#login";
                        }
                    }
                ]
            },

        ];
    } else {
        menu = [
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'Gestion pointage', icon: 'pi pi-fw pi-book', command: () => { window.location = "#/pointages" } },
            { label: 'Gestion absences', icon: 'pi pi-fw pi-clock', command: () => { window.location = "#/Absences" } },
            {
                label: 'Parametrage', icon: 'pi pi-fw pi-cog',
                items: [
                    { label: 'Sites / Localités', icon: 'pi pi-fw pi-cog', to: '/sites' },
                    { label: 'Quartiers', icon: 'pi pi-fw pi-map-marker', to: '/quartiers' },
                    { label: 'Etablissement', icon: 'pi pi-fw pi-cog', to: '/etablissement' },
                    { label: 'Utilisateurs', icon: 'pi pi-fw pi-user-plus', to: '/users' },
                    { label: 'Groupes', icon: 'pi pi-fw pi-users', to: '/groupes' },
                    { label: 'Horaires', icon: 'pi pi-fw pi-calendar', to: '/horaires' },
                    { label: 'Départements', icon: 'pi pi-fw pi-sitemap', to: '/departements' },
                    { label: 'Divisions et services', icon: 'pi pi-fw pi-plus-circle', to: '/divisions_services' },
                    { label: 'Periodes', icon: 'pi pi-fw pi-calendar', to: '/periode' },
                ]
            },
            {
                label: 'Reporting', icon: 'pi pi-fw pi-chart-line',
                items: [
                    { label: 'Liste pointage', icon: 'pi pi-fw pi-table', to: '/liste_pointage' },
                    { label: 'Situation pointage', icon: 'pi pi-fw pi-table', to: '/Situation_pointages' },
                    { label: 'Volume H / departement', icon: 'pi pi-fw pi-chart-line', to: '/Situation_pointages_departement' },
                    { label: 'Volume H / division', icon: 'pi pi-fw pi-chart-line', to: '/Situation_pointages_division' },
                    { label: 'Volume H / service', icon: 'pi pi-fw pi-chart-line', to: '/Situation_pointages_service' },
                    { label: 'Retards / tranche', icon: 'pi pi-fw pi-chart-line', to: '/Situation_tranche_retard' },
                    { label: 'Organigramme', icon: 'pi pi-fw pi-sitemap', to: '/Organigramme' }

                ]
            },
            { label: 'Gestion performances', icon: 'pi pi-fw pi-check-square', command: () => { window.location = "#/performance" } },
            { label: 'Messagerie', icon: 'pi pi-fw pi-envelope', command: () => { window.location = "#/messagerie" } },
            {
                label: 'Déconnexion', icon: 'pi pi-fw pi-sign-out', command: () => {
                    localStorage.removeItem('userconnected');
                    window.location = "/#login";
                }
            },
        ];
    }



    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onMenuClick = (event) => {
        menuClick = true;
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarMenuActive(false);

        if (layoutMode === 'overlay' && !isMobile()) {
            setOverlayMenuActive(prevState => !prevState);
        } else {
            if (isDesktop())
                setStaticMenuDesktopInactive(prevState => !prevState);
            else
                setStaticMenuMobileActive(prevState => !prevState);
        }

        event.preventDefault();
    }

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevState => !prevState)
        hideOverlayMenu();
        event.preventDefault();
    }

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;

        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null);
        else
            setActiveTopbarItem(event.item);

        event.originalEvent.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }
        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    }

    const onRootMenuItemClick = (event) => {
        setMenuActive(prevState => !prevState);
    }

    const onDocumentClick = (event) => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null)
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
            }

            hideOverlayMenu();
        }

        topbarItemClick = false;
        menuClick = false;
    }

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuDesktopInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }
        else {
            return true;
        }
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false)
    }

    const isMobile = () => {
        return window.innerWidth < 1025;
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    }

    const isSlim = () => {
        return layoutMode === 'slim';
    }

    const changeMenuMode = (event) => {
        setLayoutMode(event.menuMode);
        setStaticMenuDesktopInactive(false);
        setOverlayMenuActive(false);
    }

    const onSchemeChange = (color) => {
        setScheme(color);
        const themeLink = document.getElementById('theme-css');
        const href = themeLink.href;
        const themeFile = href.substring(href.lastIndexOf('/') + 1, href.lastIndexOf('.'));
        const themeTokens = themeFile.split('-');
        const themeName = themeTokens[1];
        changeTheme(themeName + '-' + color);
        changeLogo(color);
    }

    const changeTheme = (theme) => {
        setThemeColor(theme.split('-')[0]);
        changeStyleSheetUrl('layout-css', theme, 'layout');
        changeStyleSheetUrl('theme-css', theme, 'theme');
    }

    const onThemeChange = (theme) => {
        setThemeColor(theme)
        changeTheme(theme + '-' + scheme);
    }

    const changeStyleSheetUrl = (id, value, prefix) => {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    }

    const changeLogo = (scheme) => {
        const invoiceLogoLink = document.getElementById("invoice-logo");
        const logoUrl = `assets/layout/images/logo-${scheme === 'light' ? 'dark' : 'white'}.png`;

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    const layoutClassName = classNames('layout-wrapper', {
        'layout-horizontal': layoutMode === 'horizontal',
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-slim': layoutMode === 'slim',
        'layout-static-inactive': staticMenuDesktopInactive && layoutMode !== 'slim',
        'layout-mobile-active': staticMenuMobileActive,
        'layout-overlay-active': overlayMenuActive,
        'p-input-filled': inputStyle === 'filled'
    });

    const menuContainerClassName = classNames('layout-menu-container', { 'layout-menu-container-inactive': !isMenuVisible() })

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>

            <AppTopbar
                topbarMenuActive={topbarMenuActive} activeTopbarItem={activeTopbarItem}
                onMenuButtonClick={onMenuButtonClick}
                onTopbarMenuButtonClick={onTopbarMenuButtonClick}
                onTopbarItemClick={onTopbarItemClick} />

            <div className={menuContainerClassName} onClick={onMenuClick}>
                <div className="layout-menu-content">
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        layoutMode={layoutMode} active={menuActive} />
                    <div className="layout-menu-footer">
                        <div className="layout-menu-footer-title">TASKS</div>

                        <div className="layout-menu-footer-content">
                            <ProgressBar value={50} showValue={false}></ProgressBar>
                            Today
                            <ProgressBar value={80} showValue={false}></ProgressBar>
                            Overall
                        </div>
                    </div>
                </div>
            </div>

            <div className="layout-content">
                <AppBreadcrumb />

                <div className="layout-content-container">
                    <Route path="/dashboard" exact component={Dashboard} />
                    <Route path="/etablissement" component={Form_etablissement} />
                    <Route path="/horaires" component={Form_horaire} />
                    <Route path="/groupes" component={Form_groupe} />
                    <Route path="/departements" component={Form_depart} />
                    <Route path="/divisions_services" component={Form_divservice} />
                    <Route path="/users" component={Form_users} />
                    <Route path="/quartiers" component={Form_quartier} />
                    <Route path="/sites" component={Form_localite} />
                    <Route path="/pointages" component={Form_pointage} />
                    <Route path="/liste_pointage" component={Form_edition_pointage} />
                    <Route path="/Absences" component={Form_absence} />
                    <Route path="/Situation_pointages" component={Form_situation_pointage} />
                    <Route path="/Situation_pointages_departement" component={Form_situation_pointage_dept} />
                    <Route path="/Situation_pointages_division" component={Form_situation_pointage_div} />
                    <Route path="/Situation_pointages_service" component={Form_situation_pointage_srv} />
                    <Route path="/Situation_tranche_retard" component={Form_tranche_retard} />
                    <Route path="/periode" component={Form_periode_objectif} />
                    <Route path="/performance" component={Form_objectif} />
                    <Route path="/accueil" component={Dashboard_agent} />
                    <Route path="/Organigramme" component={Form_organigramme} />
                    <Route path="/messagerie" component={Form_Msg} />
                    <Route path="/inbox" component={Form_MsgInbox} />
                    <Route path="/settings" component={FormChangepwd} />

                </div>

                <AppFooter />

                {staticMenuMobileActive && <div className="layout-mask"></div>}
            </div>


            <AppConfig themeColor={themeColor} onThemeChange={onThemeChange}
                inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} changeMenuMode={changeMenuMode}
                ripple={ripple} onRippleChange={onRippleChange}
                scheme={scheme} onSchemeChange={onSchemeChange} />
        </div>
    );

}

export default App;
