import React, { Component } from 'react';
import  Digipointservice  from '../service/Digipointservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import './styles.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { InputTextarea } from 'primereact/inputtextarea';


export class Form_Msg extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            liste_msg: [],
            liste_pers: [],
            selectedDest: [],
            liste_file_msg: [],
            selectedMsg: null,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            contenuMsg: '',
            canalMsg: 'Mail',
            objMsg: '',
            msg: '',
            visible: false,
            showindicator: false,
        };
        this.conf_print = this.conf_print.bind(this);
        this.myservice = new Digipointservice();
    }


    conf_print(){
        this.dt.exportCSV();
    }
    componentDidMount() {
        this.rech_liste_msg();
        this.myservice.getListe_user().then(data => {
            this.setState({ liste_pers: data });
        });
    }

    rech_liste_msg() {
        //var spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
       // var spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        this.setState({ showindicator: true });
        this.myservice.get_all_msg().then(data => this.setState({ liste_msg: data, showindicator: false }));
    }

    getListeFileMsg(idMsg) {
        this.setState({ showindicator: true });
        this.myservice.get_liste_file_by_msg(idMsg).then(data => this.setState({ liste_file_msg: data, showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == 200) {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_msg();
            this.rech_liste_msg();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }


    annule_msg() {
        this.setState({
            visible: false,
            contenuMsg: '',
            objMsg: ''
        });
        this.forceUpdate();
    }


    enreg_msg() {
        if (this.state.contenuMsg == '' || this.state.selectedDest.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir le message' });
        } else {
            if (this.state.selectedDest == null || this.state.selectedDest == undefined) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les destinataires' });
                return;
            } else if (this.state.selectedDest.length <= 0) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les destinataires' });
                return;
            }
            this.setState({ showindicator: true });
            let idMsg = { idMsg: '0', contenuMsg: this.state.contenuMsg, canalMsg: this.state.canalMsg.value, objMsg: this.state.objMsg, idUserExpMsg: this.state.userconnected, iduser: this.state.userconnected.idUser, idEtabl: this.state.userconnected.idEtabl };

            let liste = [];
            for (var i = this.state.selectedDest.length; i--;) {
                liste.push(
                    {
                        idFil: i + 1,
                        idMsg: idMsg,
                        msgFil: this.state.contenuMsg,
                        idUserDest: this.state.selectedDest[i],
                        iduser: this.state.userconnected.idUser,
                        idEtabl: this.state.userconnected.idEtabl
                    }
                )
            }
            this.myservice.add_msg(liste).then(data => this.resultat(data.code, data.contenu));
        }
    }

    render() {

        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.enreg_msg();
                }} label="Valider" />
            </div>
        );


        var btn_add_new = <div style={{ textAlign: 'left', width: 150 }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="Nouveau" onClick={() => this.setState({ visible: true })} /></div>;
        var btn_export = <div style={{ textAlign: 'left', width: 150 }}><Button type="button" icon="pi pi-print" iconPos="left" label="Exporter" onClick={() => this.conf_print()} /></div>;

        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />

                <Dialog header="Nouvel message" visible={this.state.visible} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible: false })} footer={dialogFooter}>
                    <DataTable value={this.state.liste_pers} responsive={true} selection={this.state.selectedDest} style={{minHeight: '400px'}} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                        onSelectionChange={e => this.setState({ selectedDest: e.value })} alwaysShowPaginator={false} paginatorPosition="top"  paginator={true} rows={8}>
                        <Column selectionMode="multiple" style={{ width: '5%' }} />
                        <Column field="nomUser" header="Nom" style={{ width: '30%' }} filter={true} filterMatchMode='contains' />
                        <Column field="matrUser" header="Matricule" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                        <Column field="telUser" header="Téléphone" style={{ width: '12%' }} filter={true} filterMatchMode='contains' />
                        <Column field="emailUser" header="Email" style={{ width: '40%' }} filter={true} filterMatchMode='contains' />
                    </DataTable>
                    <div>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-2">
                                <label htmlFor="descCodif">Canal *</label>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <Dropdown options={[
                                    { value: 'Mail', label: 'Mail' },
                                    { value: 'SMS', label: 'SMS' },
                                    { value: 'Web', label: 'Web' }
                                ]} placeholder='Choisir un canal' filter={true} filterMatchMode='contains' filterBy='label' value={this.state.canalMsg} optionLabel='label' onChange={event => this.setState({ canalMsg: event.value })} autoWidth={false} />
                            </div>

                            <div className="p-col-12 p-md-2">
                                <label htmlFor="descCodif">Objet *</label>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <InputText value={this.state.objMsg} onChange={(e) => this.setState({ objMsg: e.target.value })} />
                            </div>
                            <div className="p-col-12 p-md-2">
                                <label htmlFor="descCodif">Message *</label>
                            </div>
                            <div className="p-col-12 p-md-10">
                                <InputTextarea rows={3} value={this.state.contenuMsg} onChange={(e) => this.setState({ contenuMsg: e.target.value })} />
                            </div>
                        </div>
                        <center>
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </center>
                    </div>
                </Dialog>


                <div className="p-grid">
                    <div className="p-col-12 p-lg-4">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="card card-w-title" style={{ height: 600 }}>
                                    <DataTable value={this.state.liste_msg} responsive={true} 
                                        paginatorPosition="top" selectionMode="single" paginator={true} rows={10} selection={this.state.selectedMsg}
                                        onRowClick={e => this.setState({ selectedMsg: e.data })} onRowSelect={e => this.setState({ selectedMsg: e.data }, () => {
                                            this.getListeFileMsg(this.state.selectedMsg.idMsg);
                                        })} paginatorLeft={btn_add_new} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers">
                                        <Column field="dateMsg" filter={true} filterMatchMode='contains' header="Date" sortable={true} style={{ width: '20%' }} />
                                        <Column field="objMsg" filter={true} filterMatchMode='contains' header="Objet" sortable={true} style={{ width: '60%' }} />
                                        <Column field="canalMsg" filter={true} filterMatchMode='contains' header="Canal" sortable={true} style={{ width: '20%' }} />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-8">
                        <div className="card card-w-title" style={{ height: 600 }}>
                            <DataTable value={this.state.liste_file_msg} paginatorPosition="top" paginator={true} rows={10} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"
                                alwaysShowPaginator={false} responsive={true} ref={(el) => { this.dt = el; }} paginatorRight={btn_export}>
                                <Column field="idUserDest.nomUser" filter={true} filterMatchMode='contains' header="Destinataire" sortable={true} style={{ width: '30%' }} />
                                <Column field="idUserDest.matrUser" filter={true} filterMatchMode='contains' header="Matricule" sortable={true} style={{ width: '14%' }} />
                                <Column field="msgFil" filter={true} filterMatchMode='contains' header="Message" sortable={true} style={{ width: '38%' }} />
                                <Column field="etatFil" filter={true} filterMatchMode='contains' header="Etat" sortable={true} style={{ width: '10%' }} />
                                <Column field="luFil" filter={true} filterMatchMode='contains' header="Lu" sortable={true} style={{ width: '8%' }} />
                            </DataTable>
                        </div>
                    </div>
 



                </div>
            </div>
        );
    }
}